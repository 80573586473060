import { useEffect, useRef, useState } from "react";
import Loading from "./Loading";

const TableLoader = ({ children, loading, response }: { children: React.ReactNode, loading: boolean, response: {} }) => {
    const [firstLoad, setfirstLoad] = useState(true);
    const [fade, setFade] = useState(false);
    const responseRef = useRef(response);
    const fadeTimeoutRef = useRef(0);
    useEffect(() => {
        clearTimeout(fadeTimeoutRef.current);
        if (loading && !firstLoad) {
            setFade(true);
            responseRef.current = response;
            return;
        }
        setFade(false);
    }, [firstLoad, loading, response]);
    useEffect(() => {
        setfirstLoad(x => x && loading);
    }, [loading]);
    if (loading && firstLoad) {
        return <Loading height={"60vh"} />;
    }
    return (<div className="table-loader-container">
        <span>
            <div className={"table-loader-loading-fade" + (fade ? " background-fade" : "")}>
                {loading && <i className="fas fa-spinner fa-pulse fa-8x"></i>}
            </div>
        </span>
        {children}
    </div>);
};

export default TableLoader;