import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Table, { TableHeader } from "../shared/Table";
import { TranslationService } from "../../services/TranslationService";
import { Link } from "react-router-dom";

import { ReportLogListResponse } from "../../entities/reports/ReportLog/ReportLogListResponse";
import { formatDateTimeLong, formatIntizaDate } from "../../utils/FormatUtils";
import Dropdown from "../shared/components/Dropdown";
import { DatePickerEditor } from "../shared/components/Editors";
import CompanyService from "../../services/CompanyService";
import LogService from "../../services/LogService";
import { ReportLogListClassRequest } from "../../entities/reports/ReportLog/ReportLogListRequest";
import TableContext from "../task/TableContext";
import ReportLogListMainProvider, { ReportLogListMainTableContextValues } from "./providers/ReportLogListMainProvider";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";
import { restTimezone } from "../../utils/ParseUtils";

export enum MainEventTypes {
    Logon = 0,
    //ManualInvoice = 1,
    //Import = 2,
    //Mail = 3,
    //Comment = 4,
    //Invite = 5,
    //Task_New = 6,
    Company = 7,
    //Person = 8,
    //Task_Edit = 9,
    //SMS = 10,
    //DeleteList = 11,
    DeleteCompany = 12,
    ResetCompany = 13,
    ResetCompanyIO = 14,
    //DeleteAction = 15,
    //EditAction = 16,
    //DeleteMessage = 17,
    //DeleteTask = 18,
    //DeletePerson = 19,
    //DeleteInvoice = 20,
    //DisableAction = 21,
    //Mail_Sent = 22,
    //Task_Done = 23,
    //ActionManual = 24,
    Production = 25,
    //ImportTemplateEdited = 26,
    //ManualTransaction = 27,
    //DeleteTransaction = 28,
    LogonFailed = 29,
    AccountBlocked = 30,
    RecoveredPassword = 31,
    //ActionsDisabledAuto = 32,
    //EditAdditionalDefinition = 33,
    //CreateAdditionalDefinition = 34,
    //DeleteAdditionalDefinition = 35,
    //CreateAdditionalItem = 36,
    //DeleteAdditionalItem = 37,
    //EditAdditionalItem = 38,
    //DeleteImport = 39,
    //EditSegment = 40,
    //DeleteSegment = 41,
    //DeleteImportTemplate = 42,
    //UploadImportTemplate = 43,
    SampleCompany = 44,
    ChangePassword = 45,
    //GroupName = 46,
    Logoff = 47,
    AccountLimitChange = 48,
    //IOSorting = 49,
    //PersonSorting = 50,
    //SaveCompanyOtherConfig = 51,
    //SaveCompanyConfig = 52,
    //UploadLogo = 53,
    //ClearLogo = 54,
    //ChangeCompanyPaymentMethod = 55,
    //EditCurreny = 56,
    //DeleteCurrency = 57,
    InviteUser = 58,
    EditUser = 59,
    DesinviteUser = 60,
    NewUser = 61,
    //TemplateFolderNameChange = 62,
    //EmailVirus = 63,
    //SegmentFailed = 64,
    //CopyToFTP = 65,
    ChangeOwner = 66,
    //EditInvoice = 67,
    //SaveCompanyPasswordConfig = 68,
    CompanyLogon = 69
}

export const ReportLogMain = () => {
    return (
        <ReportLogListMainProvider>
            <ReportLogMainComponent />
        </ReportLogListMainProvider>
    )
}


const getTranslationEventType = (keyType: any): string => {
    switch (keyType) {
        case MainEventTypes.Company:
            return "Nueva empresa";
        case MainEventTypes.Logon:
            return "Ingreso cuenta";
        case MainEventTypes.CompanyLogon:
            return "Ingreso empresa";
        case MainEventTypes.ResetCompany:
            return "Limpia empresa";
        case MainEventTypes.ResetCompanyIO:
            return "Limpia facturas y pagos";
        case MainEventTypes.DeleteCompany:
            return "Elimina empresa";
        case MainEventTypes.Production:
            return "En producción";
        case MainEventTypes.LogonFailed:
            return "Ingreso fallido";
        case MainEventTypes.RecoveredPassword:
            return "Password reset";
        case MainEventTypes.AccountBlocked:
            return "Cuenta bloqueada";
        case MainEventTypes.ChangePassword:
            return "Contraseña cambiada";
        case MainEventTypes.Logoff:
            return "Salida";
        case MainEventTypes.InviteUser:
            return "Usuario invitado";
        case MainEventTypes.EditUser:
            return "Usuario editado";
        case MainEventTypes.DesinviteUser:
            return "Usuario eliminado";
        case MainEventTypes.AccountLimitChange:
            return "Cambio de límites de la cuenta";
        case MainEventTypes.SampleCompany:
            return "Empresa de muestra";
        case MainEventTypes.NewUser:
            return "Nuevo usuario";
        case MainEventTypes.ChangeOwner:
            return "Cambiar propietario";
        default:
            return `Sin traduccion - ${keyType}`;
    }
}


const MainEventsIncludes = [
    MainEventTypes.CompanyLogon.toString(),
    MainEventTypes.ResetCompany.toString(),
    MainEventTypes.ResetCompanyIO.toString(),
    MainEventTypes.AccountBlocked.toString(),
    MainEventTypes.RecoveredPassword.toString(),
    MainEventTypes.ChangePassword.toString(),
    MainEventTypes.InviteUser.toString(),
    MainEventTypes.EditUser.toString(),
];

const ReportLogMainComponent = () => {
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ Id: x.Id, Value: x.Value ?? "" })) ?? [];
    const filteredEventTypes = Object.keys(MainEventTypes)
        .filter(key => !isNaN(Number(MainEventTypes[key as any])));
    const [dataForm, setDataForm] = useState(new ReportLogListClassRequest());
    const [from, setFrom] = useState<string>();
    const [to, setTo] = useState<string>();
    const { setRequest, response } = useContext(TableContext) as ReportLogListMainTableContextValues;

    const eventArray = filteredEventTypes
        .map((key: any) => ({
            Value: getTranslationEventType(MainEventTypes[key]),
            Id: MainEventTypes[key as any].toString()
        }))
        .filter(el => MainEventsIncludes.includes(el.Id));


    const tableHead = useMemo(() => [
        new TableHeader("date", TranslationService.translate.Date, false, false),
        new TableHeader("user", TranslationService.translate.User, false, false),
        new TableHeader("company", TranslationService.translate.Company, false, false),
        new TableHeader("action", TranslationService.translate.Action, false, false),
        new TableHeader("client", TranslationService.translate.Client, false, false),
        new TableHeader("additionalData", TranslationService.translate.AdditionalData, false, false),
    ], []);

    const handleSelect = (name: string, value?: string | number,) => {
        if (value === '-1') {
            const dataAux: any = { ...dataForm }
            delete dataAux[name];
            setDataForm({ ...dataAux })
        } else {
            setDataForm(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleDateChange = (v: string, type: string) => {
        if (type === 'to') {
            setTo(formatIntizaDate(new Date(v)));
        } else if (type === 'from') {
            setFrom(formatIntizaDate(new Date(v)));
        }
    }

    const handleExport = useCallback(async () => {
        if (response?.itemCount) {
            await LogService.exportLog({ type: dataForm.type || null, from: dataForm?.from || '', to: dataForm?.to || '' }, response.itemCount, true);
        }
    }, [dataForm?.from, dataForm?.to, dataForm.type, response?.itemCount]);

    useEffect(() => {
        const applySearchDataForm = () => {
            if (!dataForm) return;

            if (from) {
                dataForm.from = from;
                dataForm.to = to || "";
                dataForm.dates = `${from}-${to || ""}`;
            }

            setRequest({ ...dataForm });
        };
        applySearchDataForm()
    }, [dataForm, from, setRequest, to])

    return (
        <div className="container-fluid padding">
            <div className="d-flex align-items-center justify-content-between">
                <h2>{TranslationService.translate.RegisterAdmin}</h2>
                <td className="mx-3 pb-2" >
                    <Link to={`/${TranslationService.currentLanguage}/report/log`}>
                        {TranslationService.translate.RegisterGoReport}
                    </Link>
                </td>
            </div>
            <div className="mh-100 p-0">
                <div className="genericHeader">
                    <div className="d-flex col align-items-center gap-3">
                        <div className="col-md-auto">
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.ExportToXls}
                                    icon="fa-light fa-arrow-down-to-line"
                                    onClick={handleExport}
                                />
                            }
                        </div>
                        <div className="col-md-auto">
                            <Dropdown quickFilter onChange={(value) => handleSelect('userid', value)} items={getUserOptions} optionLabel={TranslationService.translate.None} defaultValue={TranslationService.translate.All} />
                        </div>
                        <div className="col-md-auto">
                            <Dropdown quickFilter onChange={(value) => handleSelect('type', value)} items={[...eventArray]} optionLabel={TranslationService.translate.None} defaultValue={TranslationService.translate.All} />
                        </div>
                        <div className="col-md-2">
                            <DatePickerEditor onChange={v => handleDateChange(v, 'from')} defaultValue={from} />
                        </div>
                        <div className="col-md-2">
                            <DatePickerEditor onChange={v => handleDateChange(v, 'to')} defaultValue={to} />
                        </div>
                    </div>
                </div>
                <Table stickyHeader={true} headers={tableHead} item={ReportLogItemMain} />
            </div>
        </div>
    );
};

const ReportLogItemMain = ({ data }: { data: ReportLogListResponse.Item }) => {
    return (
        <tr>
            <td>{formatDateTimeLong(restTimezone(new Date(data.Date)))}</td>
            <td>{data.UserName}</td>
            <td>{data.CompanyName}</td>
            <td>{data.type}</td>
            <td>{data.PersonName}</td>
            <td>
                <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.ExtraData + " " + data.Data }}></div>
            </td>
        </tr>
    );
};