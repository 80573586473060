import { useState } from "react";
import { v4 } from "uuid";
import CompanyService from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import { OptionalMap } from "../../../../utils/Utils";
import Dropdown from "../../../shared/components/Dropdown";
import { UserDropdownEditor } from "../../../shared/components/Editors";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import TooltipComponent from "../../../shared/TooltipComponent";

export const Segments = ({ data, setField, requiredManager }: EditProps) => {
    const [segments, setSegments] = useState<{ _id: string, segmentId?: string, userId?: number }[]>
    (data.segments?.map(x => ({ _id: v4(), segmentId: x.Id, userId: x.UserID }))
            ?? [{ _id: v4() }]);
    const addSegment = () => {
        setSegments(x => [...x, { _id: v4() }]);
    };
    const toValidSegments = (invalid: typeof segments) => invalid
        .filter(x => x.segmentId)
        .map(x => ({ Id: x.segmentId!, UserID: x.userId, Value: "" }));
    const setData = requiredManager.makeRequiredWithId(setField("segments"), "segments");
    const updateSegments = (newSegments: typeof segments) => {
        setSegments([...newSegments]);
        setData(toValidSegments(newSegments));
    };
    return (<div>
        {segments.map(segment =>
            <div className="row" key={segment._id}>
                <Field title={TranslationService.translate.Segment}>
                    <Dropdown items={CompanyService.getSegments()} optionLabel={TranslationService.translate.Select} defaultValue={segment.segmentId}
                        onChange={segmentId => updateSegments([...segments.filter(x => x !== segment), { ...segment, segmentId }])} />
                </Field>
                <Field title={TranslationService.translate.User}>
                    <UserDropdownEditor defaultValue={segment.userId?.toString()}
                        onChange={userId => updateSegments([...segments.filter(x => x !== segment), { ...segment, userId: OptionalMap(userId, parseInt) }])} />
                </Field>
                <Field title={undefined}>
                    <div className="d-flex align-items-center" style={{ height: 40 }}>
                        <TooltipComponent title={TranslationService.translate.Delete}>
                            <i className="fa-regular text-danger fa-trash-can px-2 pointer" onClick={() => updateSegments(segments.filter(x => x !== segment))} />
                        </TooltipComponent>
                    </div>
                </Field>
            </div>)}
        <button className="btn btn-link px-0 pt-0 pb-2" onClick={addSegment}>+ {TranslationService.translate.Add}</button>
        <ValidationMessage onChange={setData} defaultValue={toValidSegments(segments)}
            validationMethod={x => x !== undefined && x.length > 0} className={"text-danger mb-2"} />
    </div>);
};