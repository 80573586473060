import { useCallback, useEffect, useState } from "react";
import ProcessService from "../../../services/ProcessService";
import { ProcessLogListResponse } from "../../shared/entities/ProcessLogListResponse";
import { TranslationService } from "../../../services/TranslationService";
import Table, { TableHeader } from "../../shared/Table";
import TableContext, { TableContextValues } from "../../task/TableContext";
import ProcessLogItem from "./ProcessLogItem";

const ProcessLog = () => {
    const [response, setResponse] = useState<ProcessLogListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    // const [pageSize, setPageSize] = useState<number>(25);

    const requestData = async () => {
        setLoading(true);
        const result = await ProcessService.getList(currentPage);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setResponse(result);
        setPageCount(totalPages);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [currentPage]);
    useEffect(() => {
        requestDataCallback();
    }, [currentPage, requestDataCallback]);

    const setCurrentPage = (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            _setCurrentPage(page);
        }
    };

    const tableContext = new TableContextValues<ProcessLogListResponse>();
    tableContext.loading = loading;
    tableContext.error = error;
    tableContext.response = response;
    tableContext.currentPage = currentPage;
    tableContext.setCurrentPage = setCurrentPage;
    tableContext.pageCount = pageCount;
    tableContext.reload = requestData;
    tableContext.pageSize = 20;
    // tableContext.setPageSize = setPageSize;

    const tableHead: TableHeader[] = [
        new TableHeader("id", "", false, false, "long-text"),
        new TableHeader("type", TranslationService.translate.Type, false, false),
        new TableHeader("date", TranslationService.translate.Date, false, false),
        new TableHeader("user", TranslationService.translate.User, false, false),
        new TableHeader("status", TranslationService.translate.Status, false, false),
        new TableHeader("download", "", false, false),
    ];
    return (
        <div className="card">
            <div className="">
                <div className="d-flex main-panel justify-content-between my-3">
                    <h2>{TranslationService.translate.ProcessLog}</h2>
                    <button className="btn btn-primary" onClick={requestDataCallback}>{TranslationService.translate.Refresh}</button>
                </div>
            </div>
            <TableContext.Provider value={tableContext}>
                <Table headers={tableHead} item={ProcessLogItem} />
            </TableContext.Provider>
        </div>
    );
};
export default ProcessLog;