import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FilterRequest } from "../../entities/Filter/FilterRequest";
import AdvancedFilterService, { Filter, FilterActivityTypeWithDate, FilterLine } from "../../services/AdvancedFilterService";
import CompanyService from "../../services/CompanyService";
import ExportService from "../../services/ExportService";
import FilterService, { FilterCollection } from "../../services/FilterService";
import { TranslationService } from "../../services/TranslationService";
import { formatIntizaDate } from "../../utils/FormatUtils";
import { OptionalMap, sumList } from "../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Table, { TableHeader } from "../shared/Table";
import TableContext from "../task/TableContext";
import ActivityTypeService from "../../services/ActivityTypeService";
import { useEndpointData } from "../../utils/TableUtils";
import { ActivityTypeSummaryResponse } from "../../entities/company/ActivityType/ActivityTypeSummaryResponse";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

export const ReportActivityTypeSummary = () => {
    const [filter, setFilter] = useState<string>(getDefaultFilter);
    const endpoint = useCallback(() => ActivityTypeService.getSummary(filter.replace("%26selFields%3D-100", "%26selFields%3D2")), [filter]);
    const context = useEndpointData(endpoint);

    const applyFilters = (filters: string[]) => {
        baseFilters = filters;
        setFilter(AdvancedFilterService.filterStringsToQueryString(filters));
    };

    const headers: TableHeader[] = [
        new TableHeader("users", TranslationService.translate.User, false, false, "w-350px"),
        new TableHeader("clients", TranslationService.translate.ManagedCustomers, true, false),
        new TableHeader("effective", TranslationService.translate.WithEffectiveActivities, true, false),
        ...CompanyService.getActivityTypes().map(x => new TableHeader(x.ActivityTypeID.toString(), x.ActivityTypeName, true, false)),
        new TableHeader("notype", TranslationService.translate.NoType, true, false),
        new TableHeader("total", TranslationService.translate.Total, true, false),
    ];
    const doExport = async () => {
        await ExportService.requestExport("/activitytype/exportsummary", {
            filter: filter.replace("%26selFields%3D-100", "%26selFields%3D2"),
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
            currencies: CompanyService.getCurrencies(),
            activitytypes: CompanyService.getActivityTypes(),
        }, (context.pageSize || 1) * (context.pageCount ?? 1));
    };
    return (
        <TableContext.Provider value={context} >
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between">
                    <h2>{TranslationService.translate.ActivityTypeSummary}</h2>
                </div>
                <div className="p-0">
                    <div className="genericHeader">
                        <div className="d-flex actionButtonsHeader">
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.Export}
                                    icon="fa-light fa-arrow-down-to-line"
                                    onClick={doExport}
                                />
                            }
                            <AdvancedFiltersButton />
                        </div>
                    </div>
                    <div className="pt-0">
                        <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportActivity} defaultValue={filter} />
                    </div>
                    <Table headers={headers} item={ReportActivitySummaryItem} stickyHeader>
                        <ReportActivtyTypeTotal response={context.response} />
                    </Table>
                </div>
            </div>
        </TableContext.Provider>
    );
};

let baseFilters: string[] = [];
const getClientQuerystring = (userId: number | null, typeId: number | null) => {
    const filters = baseFilters;
    const clientOptions = FilterService.GetFiltersForPage(FilterCollection.Client);
    const activtyOptions = FilterService.GetFiltersForPage(FilterCollection.ReportActivity);
    const filterRequest: Filter[] = filters.map(textFirst => {
        return FilterLine.From(textFirst, activtyOptions);
    }).filter(x => !x.isEmpty());

    const dateFilter = (filterRequest.find(x => x.definition.Field === "-100") ?? {});
    const newFilter = dateFilter as FilterActivityTypeWithDate;
    newFilter.activityTypeFilter = typeId?.toString() ?? "";
    newFilter.userId = userId?.toString() ?? "";
    newFilter.definition = clientOptions.flatMap(x => x.definitions).find(x => x.Field === "-20")!;
    newFilter.kind = newFilter.definition.Type;
    const finalFilter = newFilter as Filter;
    filterRequest.includes(finalFilter) || filterRequest.push(finalFilter);

    const stringFilters = filterRequest.map(AdvancedFilterService.filterToString);

    return `/${TranslationService.currentLanguage}/client?filter=` + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...filters, ...stringFilters]) || "");
};

const getQuerystring = (userId: number | null, typeId: number | null) => {
    const filterDefinitions = FilterService.GetFiltersForPage(FilterCollection.ReportActivity).flatMap(x => x.definitions);
    const filters = baseFilters;
    const filterRequest: FilterRequest[] = [];
    if (userId !== null) {
        filterRequest.push({
            field: "-102",
            operator: 0,
            value: userId.toString()
        });
    }
    if (typeId !== null) {
        if (typeId === 0) {
            filterRequest.push({
                field: "-104",
                operator: 3,
                value: "0",
            });
        } else {
            filterRequest.push({
                field: "-104",
                operator: 0,
                value: typeId.toString()
            });
        }
    }
    const linkFilters = filterRequest.map<Filter>(x => {
        const definition = filterDefinitions.find(y => x.field === y.Field)!;
        return ({
            kind: definition.Type,
            definition,
            value: x.value,
            operator: x.operator
        });
    });

    const stringFilters = linkFilters.map(AdvancedFilterService.filterToString);

    return "reportactivity?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...filters, ...stringFilters]) || "");
};

const ReportActivitySummaryItem = ({ data }: { data: ActivityTypeSummaryResponse.Item }) => {
    const headers = CompanyService.getActivityTypes();
    return (
        <tr>
            <td>
                <Link to={getQuerystring(data.userID || null, null)}>
                    {data.user?.FullnameInv ?? (data.userID === 0 && <i>{TranslationService.translate.Automatic}</i>)}
                </Link>
            </td>
            <td className="text-end">
                <Link to={getClientQuerystring(data.userID, null)}>
                    {data.personCount}
                </Link>
            </td>
            <td className="text-end">
                <Link to={getClientQuerystring(data.userID, 0)}>
                    {data.personCountEffective}
                </Link>
            </td>
            {headers.map(header => {
                const activityType = data.activityTypes.find(x => x.activityTypeID === header.ActivityTypeID);
                if (!activityType?.cant) {
                    return <td key={header.ActivityTypeID} className="text-end">0</td>;
                }
                return (<td className="text-end" key={header.ActivityTypeID}>
                    <Link to={getQuerystring(data.userID, activityType.activityTypeID)}>{activityType.cant}</Link>
                </td>);
            })}
            <td className="text-end">
                {OptionalMap(data.activityTypes.find(x => x.activityTypeID === 0)?.cant, x => <Link to={getQuerystring(data.userID, 0)}>{x}</Link>) ?? 0}
            </td>
            <td className="text-end">
                {data.activityTypes.reduce((prev, curr) => curr.cant + prev, 0)}
            </td>
        </tr>
    );
};

export const ReportActivtyTypeTotal = ({ response }: { response?: ActivityTypeSummaryResponse }) => {
    const totals = useMemo(() => {
        if (!response?.list.length) {
            return undefined;
        }
        const totals: Record<number, number> = {};
        response.list.forEach(user => {
            user.activityTypes.forEach(x => {
                totals[x.activityTypeID] = (totals[x.activityTypeID] ?? 0) + x.cant;
            });
        });
        return totals;
    }, [response]);

    if (!response?.list.length) {
        return <tr><td></td><td></td><td></td><td></td><td></td></tr>;
    }

    return (
        <tr className="font-weight-bold">
            <td>{TranslationService.translate.Total}</td>
            <td className="text-end">
                <Link to={getClientQuerystring(null, null)}>
                    {sumList(response.list, x => x.personCount)}
                </Link>
            </td>
            <td className="text-end">
                <Link to={getClientQuerystring(null, 0)}>
                    {sumList(response.list, x => x.personCountEffective)}
                </Link>
            </td>
            {CompanyService.getActivityTypes().map(x =>
                <td className="text-end" key={x.ActivityTypeID}>
                    <Link to={getQuerystring(null, x.ActivityTypeID)}>
                        {(totals && totals[x.ActivityTypeID]) ?? 0}
                    </Link>
                </td >)
            }
            <td className="text-end">
                <Link to={getQuerystring(null, 0)}>
                    {totals?.[0] ?? "0"}
                </Link>
            </td>
            <td className="text-end">
                <Link to="reportactivity">
                    {totals && sumList(Object.values(totals), x => x)}
                </Link>
            </td>
        </tr >
    );
};

function getDefaultFilter() {
    const filterDefinitions = FilterService.GetFiltersForPage(FilterCollection.ReportActivity).flatMap(x => x.definitions);
    const definition = filterDefinitions.find(x => x.Field === "-100")!;
    const startDate = new Date();
    startDate.setDate(1);
    const endDate = new Date();
    endDate.setMonth(startDate.getMonth() + 1, 0);
    const dateFilter: Filter = {
        kind: definition.Type,
        definition,
        value: formatIntizaDate(startDate) + "-" + formatIntizaDate(endDate),
        operator: 0,
    };

    const stringFilter = AdvancedFilterService.filterToString(dateFilter);
    baseFilters = [stringFilter];

    return FilterService.GetExtraFiltersRequestString(baseFilters) || "";
}
