import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Table, { TableHeader } from "../shared/Table";
import { TranslationService } from "../../services/TranslationService";
import { Link } from "react-router-dom";
import Dropdown from "../shared/components/Dropdown";
import { ReportLogListResponse } from "../../entities/reports/ReportLog/ReportLogListResponse";
import ReportLogListProvider, { ReportLogListTableContextValues } from "./providers/ReportLogListProvider";
import { formatDateTimeLong, formatIntizaDate } from "../../utils/FormatUtils";
import CompanyService from "../../services/CompanyService";
import LogService from "../../services/LogService";
import { DatePickerEditor } from "../shared/components/Editors";
import { ReportLogListClassRequest } from "../../entities/reports/ReportLog/ReportLogListRequest";
import TableContext from "../task/TableContext";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";
import { restTimezone } from "../../utils/ParseUtils";

export enum EventTypes {
    //Logon = 0, // MainEventTypes
    ManualInvoice = 1,
    Import = 2,
    Mail = 3,
    Comment = 4,
    Invite = 5,
    Task_New = 6,
    //Company = 7, // MainEventTypes
    Person = 8,
    Task_Edit = 9,
    SMS = 10,
    DeleteList = 11,
    //DeleteCompany = 12, // MainEventTypes
    //ResetCompany = 13, // MainEventTypes
    //ResetCompanyIO = 14, // MainEventTypes
    DeleteAction = 15,
    EditAction = 16,
    DeleteMessage = 17,
    DeleteTask = 18,
    DeletePerson = 19,
    DeleteInvoice = 20,
    DisableAction = 21,
    Mail_Sent = 22,
    Task_Done = 23,
    ActionManual = 24,
    //Production = 25, // MainEventTypes
    ImportTemplateEdited = 26,
    ManualTransaction = 27,
    DeleteTransaction = 28,
    //LogonFailed = 29, // MainEventTypes
    //AccountBlocked = 30, // MainEventTypes
    //RecoveredPassword = 31, // MainEventTypes
    ActionsDisabledAuto = 32,
    EditAdditionalDefinition = 33,
    CreateAdditionalDefinition = 34,
    DeleteAdditionalDefinition = 35,
    CreateAdditionalItem = 36,
    DeleteAdditionalItem = 37,
    EditAdditionalItem = 38,
    DeleteImport = 39,
    EditSegment = 40,
    DeleteSegment = 41,
    DeleteImportTemplate = 42,
    UploadImportTemplate = 43,
    //SampleCompany = 44, // MainEventTypes
    //ChangePassword = 45, // MainEventTypes
    GroupName = 46,
    //Logoff = 47, // MainEventTypes
    //AccountLimitChange = 48, // MainEventTypes
    IOSorting = 49,
    PersonSorting = 50,
    SaveCompanyOtherConfig = 51,
    SaveCompanyConfig = 52,
    UploadLogo = 53,
    ClearLogo = 54,
    ChangeCompanyPaymentMethod = 55,
    EditCurreny = 56,
    DeleteCurrency = 57,
    //InviteUser = 58, // MainEventTypes
    //EditUser = 59, // MainEventTypes
    //DesinviteUser = 60, // MainEventTypes
    //NewUser = 61, // MainEventTypes
    TemplateFolderNameChange = 62,
    EmailVirus = 63,
    SegmentFailed = 64,
    CopyToFTP = 65,
    //ChangeOwner = 66, // MainEventTypes
    EditInvoice = 67,
    SaveCompanyPasswordConfig = 68,
    //CompanyLogon = 69 // MainEventTypes
    Whatsapp = 70,
    EditTransactionType = 71,
    DeleteTransactionType = 72,
    EditActivityType = 73,
    DeleteActivityType = 74,
    EditIOStatus = 75,
    DeleteIOStatus = 76,
    DeleteTag = 77,
    EditReportExport = 78,
    DeleteReportExport = 79
}

const getTranslationEventType = (keyType: any): string => {
    switch (keyType) {
        case EventTypes.Comment:
            return "Nuevo comentario";
        case EventTypes.Import:
            return "Importación";
        case EventTypes.Invite:
            return "Invitación";
        case EventTypes.Mail:
            return "Nuevo email";
        case EventTypes.ManualInvoice:
            return "Nueva factura";
        case EventTypes.EditInvoice:
            return "Edición factura";
        case EventTypes.Person:
            return "Edición cliente";
        case EventTypes.Task_Edit:
            return "Tarea editada";
        case EventTypes.Task_New:
            return "Nueva tarea";
        case EventTypes.DeleteList:
            return "Elimina clientes";
        case EventTypes.DeleteAction:
            return "Elimina Acción automática";
        case EventTypes.EditAction:
            return "Edición Acción automática";
        case EventTypes.DeleteMessage:
            return "Borra Email/Comentario";
        case EventTypes.DeleteInvoice:
            return "Borra Factura";
        case EventTypes.DeletePerson:
            return "Borra Cliente";
        case EventTypes.DeleteTask:
            return "Borra Tarea";
        case EventTypes.DisableAction:
            return "Acción automatica";
        case EventTypes.Task_Done:
            return "Tarea completada";
        case EventTypes.ActionManual:
            return "Accion manual";
        case EventTypes.ManualTransaction:
            return "Pago manual";
        case EventTypes.DeleteTransaction:
            return "Borra pago manual";
        case EventTypes.ImportTemplateEdited:
            return "Configura importación";
        case EventTypes.SMS:
            return "SMS enviado";
        case EventTypes.Mail_Sent:
            return "Email enviado";
        case EventTypes.EditAdditionalDefinition:
            return "Campo adicional editado";
        case EventTypes.CreateAdditionalDefinition:
            return "Campo adicional creado";
        case EventTypes.DeleteAdditionalDefinition:
            return "Campo adicional eliminado";
        case EventTypes.CreateAdditionalItem:
            return "Item de campo de lista creado";
        case EventTypes.DeleteAdditionalItem:
            return "Item de campo de lista eliminado";
        case EventTypes.EditAdditionalItem:
            return "Item de campo de lista editado";
        case EventTypes.DeleteImport:
            return "Importación eliminada";
        case EventTypes.EditSegment:
            return "Segmento editado";
        case EventTypes.DeleteSegment:
            return "Segmento eliminado";
        case EventTypes.DeleteImportTemplate:
            return "Proceso importación eliminado";
        case EventTypes.UploadImportTemplate:
            return "Plantilla importación subida";
        case EventTypes.IOSorting:
            return "Orden campos factura editado";
        case EventTypes.PersonSorting:
            return "Orden campos cliente editado";
        case EventTypes.SaveCompanyConfig:
            return "Configuración empresa editada";
        case EventTypes.SaveCompanyOtherConfig:
            return "Configuración empresa editada (otros)";
        case EventTypes.UploadLogo:
            return "Nuevo logo subido";
        case EventTypes.ClearLogo:
            return "Logo eliminado";
        case EventTypes.ChangeCompanyPaymentMethod:
            return "Métodos de pago editados";
        case EventTypes.EditCurreny:
            return "Moneda editada";
        case EventTypes.DeleteCurrency:
            return "Moneda eliminada";
        case EventTypes.EmailVirus:
            return "Email con virus";
        case EventTypes.CopyToFTP:
            return "Reporte a FTP";
        case EventTypes.SegmentFailed:
            return "Segmento fallido";
        default:
            return `Sin traduccion - ${keyType}`;
    }
}


const EventsIncludes = [
    EventTypes.ManualInvoice.toString(),
    EventTypes.Import.toString(),
    EventTypes.Mail.toString(),
    EventTypes.Comment.toString(),
    EventTypes.Invite.toString(),
    EventTypes.Task_New.toString(),
    EventTypes.Person.toString(),
    EventTypes.Task_Edit.toString(),
    EventTypes.SMS.toString(),
    EventTypes.DeleteList.toString(),
    EventTypes.DeleteAction.toString(),
    EventTypes.EditAction.toString(),
    EventTypes.DeleteMessage.toString(),
    EventTypes.DeleteTask.toString(),
    EventTypes.DeletePerson.toString(),
    EventTypes.DeleteInvoice.toString(),
    EventTypes.DisableAction.toString(),
    EventTypes.Mail_Sent.toString(),
    EventTypes.Task_Done.toString(),
    EventTypes.ActionManual.toString(),
    EventTypes.ImportTemplateEdited.toString(),
    EventTypes.ManualTransaction.toString(),
    EventTypes.DeleteTransaction.toString(),
    EventTypes.EditAdditionalDefinition.toString(),
    EventTypes.CreateAdditionalDefinition.toString(),
    EventTypes.DeleteAdditionalDefinition.toString(),
    EventTypes.CreateAdditionalItem.toString(),
    EventTypes.DeleteAdditionalItem.toString(),
    EventTypes.EditAdditionalItem.toString(),
    EventTypes.DeleteImport.toString(),
    EventTypes.EditSegment.toString(),
    EventTypes.DeleteSegment.toString(),
    EventTypes.DeleteImportTemplate.toString(),
    EventTypes.UploadImportTemplate.toString(),
    EventTypes.IOSorting.toString(),
    EventTypes.PersonSorting.toString(),
    EventTypes.SaveCompanyOtherConfig.toString(),
    EventTypes.SaveCompanyConfig.toString(),
    EventTypes.UploadLogo.toString(),
    EventTypes.ClearLogo.toString(),
    EventTypes.ChangeCompanyPaymentMethod.toString(),
    EventTypes.EditCurreny.toString(),
    EventTypes.DeleteCurrency.toString(),
    EventTypes.EmailVirus.toString(),
    EventTypes.CopyToFTP.toString(),
]

export enum ResponseError {
    "Fail-Pending"
}

export const ReportLog = () => {
    return (
        <ReportLogListProvider>
            <ReportLogComponent />
        </ReportLogListProvider>
    )
}

const ReportLogComponent = () => {
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ Id: x.Id, Value: x.Value ?? "" })) ?? [];
    const filteredEventTypes = Object.keys(EventTypes).filter(key => !isNaN(Number(EventTypes[key as any])));

    const [dataForm, setDataForm] = useState(new ReportLogListClassRequest());
    const [from, setFrom] = useState<string>();
    const [to, setTo] = useState<string>();
    const { setRequest, response } = useContext(TableContext) as ReportLogListTableContextValues;

    const eventArray = filteredEventTypes
        .map((key: any) => ({
            Value: getTranslationEventType(EventTypes[key]),
            Id: EventTypes[key as any].toString()
        }))
        .filter(el => EventsIncludes.includes(el.Id));

    const tableHead = useMemo(() => [
        new TableHeader("date", TranslationService.translate.Date, false, false),
        new TableHeader("user", TranslationService.translate.User, false, false),
        new TableHeader("action", TranslationService.translate.Action, false, false),
        new TableHeader("client", TranslationService.translate.Client, false, false),
        new TableHeader("additionalData", TranslationService.translate.AdditionalData, false, false),
    ], []);

    const handleSelect = (name: string, value?: string | number,) => {
        if (value === '-1') {
            const dataAux: any = { ...dataForm }
            delete dataAux[name];
            setDataForm({ ...dataAux })
        } else {
            setDataForm(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleDateChange = (v: string, type: string) => {
        if (type === 'to') {
            setTo(formatIntizaDate(new Date(v)));
        } else if (type === 'from') {
            setFrom(formatIntizaDate(new Date(v)));
        }
    }

    const handleExport = useCallback(async () => {
        if (response?.itemCount) {
            const reponseExport = await LogService.exportLog({ type: dataForm.type || null, from: dataForm?.from || '', to: dataForm?.to || '', userid: dataForm?.userid?.toString() || '' }, response.itemCount);
            console.log(reponseExport)
        }
    }, [dataForm?.from, dataForm?.to, dataForm.type, dataForm?.userid, response?.itemCount]);


    useEffect(() => {
        const applySearchDataForm = () => {
            if (!dataForm) return;

            if (from) {
                dataForm.from = from;
                dataForm.to = to || "";
                dataForm.dates = `${from}-${to || ""}`;
            }

            setRequest({ ...dataForm });
        };
        applySearchDataForm()
    }, [dataForm, from, setRequest, to])

    return (
        <div className="container-fluid padding">
            <div className="d-flex align-items-center justify-content-between">
                <h2>{TranslationService.translate.Register}</h2>
                <Link to={`/${TranslationService.currentLanguage}/report/logMain`}>
                    {TranslationService.translate.RegisterGoReportAdmin}
                </Link>
            </div>
            <div className="mh-100 p-0">
                <div className="genericHeader">
                    <div className="d-flex col align-items-center gap-3">
                        {CompanyService.canDo("export") &&
                            <ButtonTooltipIcon
                                title={TranslationService.translate.Export}
                                icon="fa-light fa-arrow-down-to-line"
                                onClick={handleExport}
                            />
                        }
                        <div className="col-auto">
                            <Dropdown quickFilter onChange={(value) => handleSelect('userid', value)} items={getUserOptions} optionLabel={TranslationService.translate.None} defaultValue={TranslationService.translate.All} />
                        </div>
                        <div className="col-auto">
                            <Dropdown quickFilter onChange={(value) => handleSelect('type', value)} items={[...eventArray]} optionLabel={TranslationService.translate.None} defaultValue={TranslationService.translate.All} />
                        </div>
                        <div className="col-auto">
                            <DatePickerEditor onChange={v => handleDateChange(v, 'from')} defaultValue={from} />
                        </div>
                        <div className="col-auto">
                            <DatePickerEditor onChange={v => handleDateChange(v, 'to')} defaultValue={to} />
                        </div>
                    </div>
                </div>
                <Table stickyHeader={true} headers={tableHead} item={ReportLogItem} />
            </div>
        </div>
    );
};

const ReportLogItem = ({ data }: { data: ReportLogListResponse.Item }) => {
    return (
        <tr>
            <td>{formatDateTimeLong(restTimezone(new Date(data.Date)))}</td>
            <td>{data.UserName}</td>
            <td>{data.type}</td>
            <td>{data.PersonName}</td>
            <td>
                <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Data }}></div>
            </td>
        </tr>
    );
};