import { useCallback, useEffect, useState } from "react";
import ClientService from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import ReportsBrackets from "./components/ReportsBrackets";
import ReportsCardContainer from "./components/ReportsCardContainer";
import { ReportCollectionType } from "../client/entities/ClientDebtRecoveryAnalysisRequest";
import { formatCurrency, formatIntizaDate } from "../../utils/FormatUtils";
import { percentFormatCollection } from "../../utils/PercentCollection";
import { DatePickerEditor } from "../shared/components/Editors";
import ReportsCardItem from "./components/ReportsCardItem";
import Loading from "../shared/components/Loading";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import FilterService, { FilterCollection, FilterEntity } from "../../services/FilterService";
import ErrorMessage from "../shared/components/Error";
import moment from "moment";
import { useHistory } from "react-router-dom";
import AdvancedFilterService, { Filter, FilterActivityTypeWithDate } from "../../services/AdvancedFilterService";

function isValidDate(fechaString: string) {
    const fecha = Date.parse(fechaString);
    return !isNaN(fecha) && new Date(fecha).toString() !== "Invalid Date";
}

export const encodeFiltersToLink = (filters: string[], customIndex?: number) => {
    let counter = customIndex || 0;

    return filters.map((value) => {
        counter++;
        return "filter" + counter + "=" + encodeURIComponent(value);
    }).join("&");
};

const getDmy = (date: string) => {
    const d = date.substring(6, 8);
    const m = date.substring(4, 6);
    const y = date.substring(0, 4);
    return [d, m, y];
};
const intizaDateFormat = "YYYYMMDD";
export const ReportCollection = () => {
    const [msgError, setMsgError] = useState<string>();
    const [response, setResponse] = useState<ReportCollectionType>();
    const [loading, setLoading] = useState(true);
    const [filtersSelected, setFiltersSelected] = useState({
        from: moment().subtract(1, "months").startOf("month").format(intizaDateFormat),
        to: moment().subtract(1, "months").endOf("month").format(intizaDateFormat),
        filter: ""
    });
    const [filtersMap, setFiltersMap] = useState<string[]>([]);

    const history = useHistory();

    const getData = useCallback(async () => {
        if (!filtersSelected.from || !filtersSelected.to) {
            setMsgError(TranslationService.translate.CompleteToContinue);
            return;
        }
        setLoading(true);
        const result = await ClientService.getDebtRecoveryAnalysis(filtersSelected);
        if (result instanceof Error) {
            setMsgError(TranslationService.translate.ErrorProcessingRequest);
            setLoading(false);
            setResponse(undefined);
            return;
        }
        setMsgError(undefined);
        setResponse(result);
        setLoading(false);
    }, [filtersSelected]);

    useEffect(() => { getData(); }, [getData]);

    const handleFilterChange = (name: string, value: string) => {
        let dataValue: string | undefined = undefined;
        if (isValidDate(value)) {
            dataValue = formatIntizaDate(new Date(value));
        }
        setFiltersSelected(prev => ({ ...prev, [name]: dataValue }));
    };

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters]);
        const filterEncoded = AdvancedFilterService.filterStringsToQueryString(filters);
        setFiltersSelected(p => ({ ...p, filter: filterEncoded }));
    };

    const navigateClientFilter = useCallback((customerKey: number) => {
        let customPath = `/${TranslationService.currentLanguage}`;

        const [dsdr, msdr, ysdr] = getDmy(filtersSelected.from);
        const [dedr, medr, yedr] = getDmy(filtersSelected.to);

        switch (customerKey) {
            case 0:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}${filtersMap.length > 0 ? "&filter=" + encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filtersMap) || "") : ""}`;
                break;
            case 4:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}&to=${yedr}-${medr}-${dedr}${filtersMap.length > 0 ? "&filter=" + encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filtersMap) || "") : ""}`;
                break;
            case 6:
                customPath = `${customPath}/report/historyclientlist?from=${ysdr}-${msdr}-${dsdr}&to=${yedr}-${medr}-${dedr}${filtersMap.length > 0 ? "&filter=" + encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filtersMap) || "") : ""}`;
                break;
            case 7:
                customPath = `${customPath}/report/historyiolist?from=${ysdr}-${msdr}-${dsdr}${filtersMap.length > 0 ? "&filter=" + encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filtersMap) || "") : ""}`;
                break;
            case 8:
                customPath = `${customPath}/report/historyiolist?from=${ysdr}-${msdr}-${dsdr}&to=${yedr}-${medr}-${dedr}${filtersMap.length > 0 ? "&filter=" + encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filtersMap) || "") : ""}`;
                break;
        }
        history.push(customPath);
    }, [history, filtersMap, filtersSelected.from, filtersSelected.to]);

    const getFilterFor = (entity: FilterEntity, fieldId: string, value: string) => {
        const options = FilterService.GetFiltersForEntity(entity);
        const definition = options.definitions.find(x => x.Field === fieldId)!;
        const filter: Filter = {
            definition,
            kind: definition.Type,
            value,
        };
        return filter;
    };

    const redirectToPayment = () => {
        const dateFilter = getFilterFor(FilterEntity.Payment, "-303", filtersSelected.from + "-" + filtersSelected.to);
        const dueDateFilter = getFilterFor(FilterEntity.Invoice, "-1023", "19700101-" + moment(filtersSelected.from, intizaDateFormat).add(-1, "days").format(intizaDateFormat));
        const filters = [
            AdvancedFilterService.filterToString(dateFilter),
            AdvancedFilterService.filterToString(dueDateFilter),
            ...filtersMap];
        const url = `/${TranslationService.currentLanguage}/report/payment?filter=${encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filters))}`;
        history.push(url);
    };
    const redirectToClient = (activityTypeId: number) => {
        const clientFilter = getFilterFor(FilterEntity.Client, "-22", filtersSelected.from + "-" + filtersSelected.to);
        const activityTypeWithdate = clientFilter as FilterActivityTypeWithDate;
        activityTypeWithdate.activityTypeFilter = activityTypeId.toString();
        activityTypeWithdate.userId = "0";
        const filters = [
            AdvancedFilterService.filterToString(clientFilter),
            ...filtersMap];
        const url = `/${TranslationService.currentLanguage}/client?filter=${encodeURIComponent(AdvancedFilterService.filterStringsToQueryString(filters))}`;
        history.push(url);
    };

    return (
        <div className="container-fluid padding reports">
            <h2 className="p-0">{TranslationService.translate.RecoveryAnalysis}</h2>
            <div className="p-0 mb-2">
                <div className="genericHeader mb-0">
                    <div className="searcherFilterHeader align-items-center">
                        <AdvancedFiltersButton />
                        <label className="mb-0" style={{ minWidth: 100 }}>{TranslationService.translate.StartDatev2}:</label>
                        <DatePickerEditor onChange={v => handleFilterChange("from", v)} defaultValue={filtersSelected.from} />

                        <label className="mb-0" style={{ minWidth: 80 }}>{TranslationService.translate.EndDate}:</label>
                        <DatePickerEditor onChange={v => handleFilterChange("to", v)} defaultValue={filtersSelected.to} />

                        <button type="button" className="btn btn-primary py-1 " onClick={getData}>{TranslationService.translate.Apply}</button>

                    </div>
                </div>
                <div className="isEmpty">
                    <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportCollection} />
                </div>
            </div>

            {loading &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <Loading />
                </div>}
            {!loading && msgError &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <ErrorMessage message={msgError} onRefresh={getData} />
                </div>}
            {!loading && !msgError && response &&
                <div className="d-flex tree pb-0 ">
                    <div className="d-flex align-items-center">
                        <ReportsCardContainer title={TranslationService.translate.InitialSituation} tooltip={false}>
                            <ReportsCardItem title={TranslationService.translate.ClientDebt} valueItem={response.data[0].Cant} action={() => navigateClientFilter(0)} />
                            <ReportsCardItem title={TranslationService.translate.TotalPending} valueItem={formatCurrency(response.data[0].Amount)} action={() => navigateClientFilter(7)} />
                        </ReportsCardContainer>
                    </div>
                    <div className="d-flex gap-4">
                        <ReportsBrackets />
                        <div className="d-flex flex-column justify-content-center gap-3">
                            <ReportsCardContainer title={TranslationService.translate.ManualActivity}>
                                <ReportsCardItem
                                    title={TranslationService.translate.Clients}
                                    valueItem={response.data[1].Cant}
                                    percent={percentFormatCollection(response.data[1].Cant, response.data[0].Cant)} action={() => redirectToClient(4)} />
                                <ReportsCardItem
                                    title={TranslationService.translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[1].Amount)}
                                    percent={percentFormatCollection(response.data[1].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Collected}
                                    valueItem={formatCurrency(response.data[1].Paid)}
                                    percent={percentFormatCollection(response.data[1].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Pending}
                                    valueItem={formatCurrency(response.data[1].Amount - response.data[1].Paid)}
                                    percent={percentFormatCollection((response.data[1].Amount - response.data[1].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>

                            <ReportsCardContainer title={TranslationService.translate.AutomaticManagement}>
                                <ReportsCardItem
                                    title={TranslationService.translate.Clients}
                                    valueItem={response.data[2].Cant}
                                    percent={percentFormatCollection(response.data[2].Cant, response.data[0].Cant)} action={() => redirectToClient(5)} />
                                <ReportsCardItem
                                    title={TranslationService.translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[2].Amount)}
                                    percent={percentFormatCollection(response.data[2].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Collected}
                                    valueItem={formatCurrency(response.data[2].Paid)}
                                    percent={percentFormatCollection(response.data[2].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Pending}
                                    valueItem={formatCurrency(response.data[2].Amount - response.data[2].Paid)}
                                    percent={percentFormatCollection((response.data[2].Amount - response.data[2].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>

                            <ReportsCardContainer title={TranslationService.translate.WithoutManagement}>
                                <ReportsCardItem
                                    title={TranslationService.translate.Clients}
                                    valueItem={response.data[3].Cant}
                                    percent={percentFormatCollection(response.data[3].Cant, response.data[0].Cant)}
                                    action={() => redirectToClient(3)} />
                                <ReportsCardItem
                                    title={TranslationService.translate.InitialDebt}
                                    valueItem={formatCurrency(response.data[3].Amount)}
                                    percent={percentFormatCollection(response.data[3].Amount, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Collected}
                                    valueItem={formatCurrency(response.data[3].Paid)}
                                    percent={percentFormatCollection(response.data[3].Paid, response.data[0].Amount)} />
                                <ReportsCardItem title={TranslationService.translate.Pending}
                                    valueItem={formatCurrency(response.data[3].Amount - response.data[3].Paid)}
                                    percent={percentFormatCollection((response.data[3].Amount - response.data[3].Paid), response.data[0].Amount)} />
                            </ReportsCardContainer>
                        </div>
                        <ReportsBrackets revert={true} />
                        <div className="d-flex align-items-center">
                            <ReportsCardContainer title={TranslationService.translate.ClosingSituation} tooltip={false}>
                                <ReportsCardItem
                                    title={TranslationService.translate.ClientDebt}
                                    valueItem={response.data[4].Cant}
                                    percent={percentFormatCollection(response.data[4].Cant, response.data[0].Cant)}
                                    action={() => navigateClientFilter(4)} />
                                <ReportsCardItem
                                    title={TranslationService.translate.TotalCollected}
                                    valueItem={formatCurrency(response.data[4].Paid)}
                                    percent={percentFormatCollection(response.data[4].Paid, response.data[0].Amount)}
                                    action={redirectToPayment} />
                                <ReportsCardItem
                                    title={TranslationService.translate.TotalPending}
                                    valueItem={formatCurrency(response.data[4].Amount)}
                                    percent={percentFormatCollection(response.data[4].Amount, response.data[0].Amount)}
                                    action={() => navigateClientFilter(8)} />
                            </ReportsCardContainer>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

