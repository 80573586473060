import { useCallback, useContext, useEffect, useState } from "react";
import { ReportExportListResponse } from "../../entities/reports/ReportExport/ReportExportListResponse";
import CompanyService, { DataType } from "../../services/CompanyService";
import ReportExportService from "../../services/ReportExportService";
import ErrorMessage from "../shared/components/Error";
import Loading from "../shared/components/Loading";
import FloatingPanelService from "../shared/FloatingPanel";
import { TranslationService } from "../../services/TranslationService";
import Table, { TableHeader, TableHeaderWithFieldId } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import ReportExportEdit from "./ReportExportEdit";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";

const ReportExport = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportExportListResponse>({} as ReportExportListResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        setLoading(true);
        const result = await ReportExportService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const tableValues = new TableContextValues();
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;

    const headers: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader(
                "report",
                translate.Report,
                false,
            ),
            fieldId: "Report",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "name",
                translate.Name,
                false,
            ),
            fieldId: "name",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "user",
                translate.User,
                false
            ),
            fieldId: "user",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "protected",
                translate.Protected,
                false
            ),
            fieldId: "protected",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "Public",
                translate.Public,
                false
            ),
            fieldId: "Public",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "CustomerSite",
                translate.CustomerSite,
                false,
            ),
            fieldId: "CustomerSite",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "Default",
                translate.Default,
                false,
            ),
            fieldId: "Default",
            type: DataType.Text,
        },
    ];

    const reportTypes = [
        translate.Activity,
        translate.Invoice,
        translate.Customer,
        translate.Activity + "-" + translate.Invoice,
        "",
        translate.Payment,
    ];

    const showEditPanel = (report: number) => {
        FloatingPanelService.showPanel({
            children: <ReportExportEdit data={{ report } as ReportExportListResponse.Item} reload={requestDataCallback} />,
            title: reportTypes[report],
            width: 600,
            height: 800,
            position: "center",
            showBackdrop: true
        });
    };

    const createItems: MenuButtonActions = reportTypes
        .map((x, i) => ({
            text: x && translate.NewTemplateOf.replace("{name}", x),
            action: () => showEditPanel(i)
        })).filter(x => x.text !== "");

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorProcessingRequest} />);
    }

    return (
        <div className="container-fluid padding">
            <div className="d-flex justify-content-between">
                <h2>{TranslationService.translate.ReportTemplates}</h2>
            </div>
            <div className="mh-100 p-0">
                <div className="genericHeader">
                    <MenuButton actions={createItems} text={translate.NewTemplate} showTitle icon="fa-light fa-plus" hideChevron />
                </div>
                <TableContext.Provider value={tableValues}>
                    <Table headers={headers} item={ReportExportItem} stickyHeader />
                </TableContext.Provider>
            </div>
        </div>
    );
};

const ReportExportItem = ({ data }: { data: ReportExportListResponse.Item }) => {
    const { translate } = TranslationService;
    const { reload } = useContext(TableContext);

    const reportTypes = [
        translate.Activity,
        translate.Invoice,
        translate.Customer,
        translate.Activity + "-" + translate.Invoice,
        "",
        translate.Payment,
    ];

    const showEditPanel = () => {
        FloatingPanelService.showPanel({
            children: <ReportExportEdit data={data} reload={reload} />,
            title: reportTypes[data.report],
            width: 600,
            height: 800,
            position: "center",
            showBackdrop: true
        });
    };

    return (
        <tr onClick={showEditPanel} className="pointer">
            <td>{reportTypes[data.report]}</td>
            <td>{data.Name}</td>
            <td>{CompanyService.getUsers().find(x => x.Id === data.Creator.toString())?.Value}</td>
            <td>{data.Protected && <i className={"far fa-fw me-2 fa-check"} />}</td>
            <td>{data.Public && <i className={"far fa-fw me-2 fa-check"} />}</td>
            <td>{data.Portal && <i className={"far fa-fw me-2 fa-check"} />}</td>
            <td>{data.Default && <i className={"far fa-fw me-2 fa-check"} />}</td>
        </tr>
    );
};

export default ReportExport;