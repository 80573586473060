import { useCallback, useEffect, useState } from "react";
import moment from "moment"; import { useHistory } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import ReportsBrackets from "./components/ReportsBrackets";
import ReportsCard from "./components/ReportsCard";
import ClientService from "../../services/ClientService";
import { ClientReportPaymentAnalysis, ReportPaymentAnalysisResponseType } from "../client/entities/ClientDebtRecoveryAnalysisRequest";
import Loading from "../shared/components/Loading";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import { FilterCollection } from "../../services/FilterService";
import { formatCurrency, formatDateDigits } from "../../utils/FormatUtils";
import RangedDatepicker from "../shared/components/DateRangePicker";
import ErrorMessage from "../shared/components/Error";
import AdvancedFilterService from "../../services/AdvancedFilterService";

const today = moment();
const firstDayDefault = today.clone().subtract(1, "months").startOf("month");
const firstHalfDayDefault = today.clone().subtract(2, "months").startOf("month").add(16, "days");
const lastDayDefault = today.clone().subtract(1, "months").endOf("month");

const getDmy = (date: string) => {
    const d = date.substring(6, 8);
    const m = date.substring(4, 6);
    const y = date.substring(0, 4);
    return [d, m, y];
};

const encodeFiltersToLink = (filters: string[], customIndex?: number) => {
    let counter = customIndex || 0;

    return filters.map((value) => {
        counter++;
        return "filter" + counter + "=" + encodeURIComponent(value);
    }).join("&");
};

const ReportPaymentAnalysis = () => {
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState<ReportPaymentAnalysisResponseType>();
    const [filtersSelected, setFiltersSelected] = useState<ClientReportPaymentAnalysis>({
        daterange: firstDayDefault.format("YYYYMMDD") + "-" + lastDayDefault.format("YYYYMMDD"),
        activityrange: firstHalfDayDefault.format("YYYYMMDD") + "-" + lastDayDefault.format("YYYYMMDD"),
        filter: ""
    });
    const [filtersMap, setFiltersMap] = useState<string[]>([]);

    const history = useHistory();
    const { translate, currentLanguage } = TranslationService;

    const getData = async () => {
        setLoading(true);
        if (!filtersSelected.daterange || !filtersSelected.activityrange) {
            setMsgError(translate.CompleteToContinue);
            setLoading(false);
            setError(true);
            return;
        }
        const result = await ClientService.getPaymentsAnalysis(filtersSelected);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            setResponse(undefined);
            return;
        }
        setMsgError(undefined);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters]);
        const filterEncoded = AdvancedFilterService.filterStringsToQueryString(filters);
        setFiltersSelected(p => ({ ...p, filter: filterEncoded }));
    };

    const DateTextboxValueDateRange = (prop: { defaultValue?: string | null }) => {
        const onChange = (start?: Date, end?: Date) => {
            if (start === undefined) {
                setFiltersSelected(p => ({ ...p, daterange: "" }));
                return;
            }
            if (end === undefined) {
                setFiltersSelected(p => ({ ...p, daterange: "" }));
                return;
            }
            const value = (start.setHours(0, 0, 0, 0) === end.setHours(0, 0, 0, 0) ? formatDateDigits(start, "ja") : formatDateDigits(start, "ja") + "-" + formatDateDigits(end, "ja"))
                .replaceAll("/", "");
            setFiltersSelected(p => ({ ...p, daterange: value }));
        };
        return (
            <div className="col">
                <RangedDatepicker onChange={onChange} defaultValue={prop.defaultValue} />
            </div>
        );
    };

    const DateTextboxValueActivityRange = (prop: { defaultValue?: string | null }) => {
        const onChange = (start?: Date, end?: Date) => {
            if (start === undefined) {
                setFiltersSelected(p => ({ ...p, activityrange: "" }));
                return;
            }
            if (end === undefined) {
                setFiltersSelected(p => ({ ...p, activityrange: "" }));
                return;
            }
            const value = (start.setHours(0, 0, 0, 0) === end.setHours(0, 0, 0, 0) ? formatDateDigits(start, "ja") : formatDateDigits(start, "ja") + "-" + formatDateDigits(end, "ja"))
                .replaceAll("/", "");
            setFiltersSelected(p => ({ ...p, activityrange: value }));
        };
        return (
            <div className="col">
                <RangedDatepicker onChange={onChange} defaultValue={prop.defaultValue} />
            </div>
        );
    };

    const navigateClientFilter = useCallback((customerKey: number) => {
        const [startDaterange, endDaterange] = filtersSelected.daterange.split("-");
        const [startActivityrange, endActivityrange] = filtersSelected.activityrange.split("-");

        const [dsdr, msdr, ysdr] = getDmy(startDaterange);
        const [dedr, medr, yedr] = getDmy(endDaterange || startDaterange);
        const [dsar, msar, ysar] = getDmy(startActivityrange);
        const [dear, mear, year] = getDmy(endActivityrange || startActivityrange);

        let customPath = `/${currentLanguage}/client?filter=filter0%3dselFields%253d-19%2526`;
        const dateRangePath = `selDate%253d${ysdr}${msdr}${dsdr}-${yedr}${medr}${dedr}%26`;
        const extendedPath = "filter1%3dselFields%253d-20%2526";
        const dateRangePathActivity = `selDateActivityType%253d${ysar}${msar}${dsar}-${year}${mear}${dear}%2526`;

        switch (customerKey) {
            case 0:
                customPath = `${customPath + dateRangePath}${filtersMap ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ""}`;
                break;
            case 1:
                customPath = `${customPath + dateRangePath + extendedPath + dateRangePathActivity}selActivityType%253d0${filtersMap ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ""}`;
                break;
            case 2:
                customPath = `${customPath + dateRangePath + extendedPath + dateRangePathActivity}selActivityType%253d5${filtersMap ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ""}`;
                break;
            case 3:
                customPath = `${customPath + dateRangePath + extendedPath + dateRangePathActivity}selActivityType%253d2${filtersMap ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ""}`;
                break;
            case 4:
                customPath = `${customPath + dateRangePath + extendedPath + dateRangePathActivity}selActivityType%253d3${filtersMap ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 1) || "") : ""}`;
                break;
        }
        history.push(customPath);
    }, [currentLanguage, filtersSelected.daterange, history, filtersSelected.activityrange, filtersMap]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getData(); }, [filtersSelected.filter]);

    return (
        <div className="container-fluid padding reports">
            <h2 className="p-0">{translate.PaymentAnalysis}</h2>
            <div className="p-0 mb-3">
                <div className="genericHeader mb-0">
                    <div className="d-flex col align-items-center gap-3">
                        <AdvancedFiltersButton />
                        <div className="col">
                            <div className="row align-items-center" >
                                <label style={{ minWidth: 150 }} className="col-auto form-label mb-0 text-end">{translate.PeriodoPagos}:</label>
                                <div className="col-md-7">
                                    <DateTextboxValueDateRange defaultValue={filtersSelected.daterange} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row align-items-center">
                                <label style={{ minWidth: 155 }} className="col-auto form-label mb-0 text-end">{translate.PeriodoGestiones}:</label>
                                <div className="col-md-7">
                                    <DateTextboxValueActivityRange defaultValue={filtersSelected.activityrange} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex align-items-center gap-4">
                                <button type="button" className=" btn btn-primary" onClick={getData}>{translate.ApplyFilters}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-0 isEmpty">
                    <AdvancedFilters page={FilterCollection.ReportPaymentAnalysis} onFilterApply={applyFilters} />
                </div>
            </div>

            {loading &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <Loading />
                </div>}
            {!loading && error &&
                <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                    <ErrorMessage message={msgError} onRefresh={getData} />
                </div>}

            {!loading && !error && response &&
                <div className="d-flex tree">
                    <div className="d-flex align-items-center">
                        <ReportsCard
                            type={"PaymentAnalysis"}
                            title={translate.PaymentsInThePeriod}
                            action={() => navigateClientFilter(0)}
                            customers={response.personCant}
                            total={formatCurrency(response.paymentTotal)} />
                    </div>
                    <div className="d-flex gap-4">
                        <ReportsBrackets />
                        <div className="d-flex flex-column justify-content-center gap-3">
                            <ReportsCard
                                type={"PaymentAnalysis"}
                                title={translate.WithEffectiveActivities}
                                action={() => navigateClientFilter(1)}
                                customers={response.activitycustomers}
                                total={formatCurrency(response.activitypayments)} />
                            <ReportsCard
                                action={() => navigateClientFilter(2)}
                                type={"PaymentAnalysis"}
                                title={translate.WithAutomaticActivities}
                                customers={response.autocustomers}
                                total={formatCurrency(response.autopayments)} />
                            <ReportsCard
                                action={() => navigateClientFilter(3)}
                                type={"PaymentAnalysis"}
                                title={translate.WithUneffectiveActivities}
                                customers={response.activitycustomersnoeff}
                                total={formatCurrency(response.activitypaymentsnoeff)} />
                            <ReportsCard
                                action={() => navigateClientFilter(4)}
                                type={"PaymentAnalysis"}
                                title={translate.WithoutActivities}
                                customers={response.noactivitycustomers}
                                total={formatCurrency(response.noactivitypayments)} />
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default ReportPaymentAnalysis;