import { useContext, useEffect, useState } from "react";
import CompanyService, { DataType } from "../../services/CompanyService";
import { FilterCollection, } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { formatCurrency, formatNumber, formatDateShort } from "../../utils/FormatUtils";
import { InvoiceList } from "../client/invoice/entities/InvoiceListResponse";
import { TableColumn } from "../client/invoice/InvoiceTableItem";
import InvoiceTableProvider, { InvoiceTableContextValues } from "../client/invoice/InvoiceTableProvider";
import InvoiceTableTotal from "../client/invoice/InvoiceTableTotal";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Table, { TableHeaderWithFieldId } from "../shared/Table";
import { Link, useLocation } from "react-router-dom";
import TableContext from "../task/TableContext";
import ClientService, { ClientFields } from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";
import { ActivityContentTableData } from "./ReportPayment";
import InvoiceDetail from "../client/invoice/InvoiceDetail";
import FloatingPanelService from "../shared/FloatingPanel";
import GroupService from "../../services/GroupService";
import Dropdown from "../shared/components/Dropdown";
import SearchInput from "../shared/components/SearchInput";

const ReportInvoice = () => {
    const tableHeaders = getTableHeaders();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const filterQS = urlParams.get("filter") ? [...urlParams.entries()].map((x, i) => "filter" + i.toString() + "=" + x[1].replace("filter0=", "")).join("&") : "";
    const [quickFilter, setQuickFilter] = useState<number | undefined>(1);

    const invoiceFilters = [
        { value: 1, text: TranslationService.translate.PendingInvoices },
        { value: 0, text: TranslationService.translate.DueInvoices },
        { value: 4, text: TranslationService.translate.ClaimableInvoices },
        { value: 2, text: TranslationService.translate.AllInvoices },
    ];

    const onChangeQuickFilter = (value: number) => {
        setQuickFilter(value);
    };

    return (
        <InvoiceTableProvider pagesize={50} extraFilters={filterQS} quickfilter={quickFilter?.toString() ?? ""}>
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between">
                    <h2>{TranslationService.translate.Invoices}</h2>
                </div>
                <div className="mh-100 p-0">
                    <div className="genericHeader">
                        <div className="searcherFilterHeader">
                            <ExportButton />
                            <AdvancedFiltersButton />
                            <div className="d-flex align-items-center">
                                <p className="text-granite-gray me-3">{TranslationService.translate.Status}:</p>
                                <Dropdown quickFilter defaultValue={quickFilter} onChange={onChangeQuickFilter} items={invoiceFilters} />
                            </div>
                        </div>
                        <Search />
                    </div>
                    <div className="pt-0">
                        <AdvancedFilters page={FilterCollection.ReportInvoice} defaultValue={filterQS} />
                    </div>
                    <Table headers={tableHeaders} item={ReportInvoiceItem} stickyHeader={true}>
                        <InvoiceTableTotal tableHeaders={tableHeaders} />
                    </Table>
                </div>
            </div>
        </InvoiceTableProvider>
    );
};

export const ExportButton = ({ ReportTypeId = 1 }: { ReportTypeId?: number }) => {
    const { getFilters, response } = useContext(TableContext) as InvoiceTableContextValues;
    const exportInvoices = (reportId: number | null) => {
        InvoiceService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === ReportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader" style={{ marginTop: 1 }}>
        {CompanyService.canDo("export") &&
            <MenuButton text={TranslationService.translate.Export} actions={exportItems} extraClassName="select-intiza-outline" icon="fa-light fa-arrow-down-to-line" hideChevron />}
    </div>);
};

export const ReportInvoiceItem = ({ data }: { data: InvoiceList.Item }) => {
    const tableHeaders = getTableHeaders();
    const clientFieldIds = ClientService.getClientHeaders().map(x => x.fieldId);
    const specialFields = {
        "last-activity": (x: InvoiceList.Item) => ActivityContentTableData({ Activity: x.LastLogMessage } as Parameters<typeof ActivityContentTableData>[0]),
        "referenceNumber": (x: InvoiceList.Item) => (<td className="is-link">
            <span className="w-md pointer txt-blue" onClick={() => FloatingPanelService.showPanel({
                children: <InvoiceDetail invoiceId={x.IOID} canEdit={false} reload={() => { }} />,
                title: TranslationService.translate.Invoice,
                width: 750,
                height: 800,
            })}>
                {data.referenceNumber}
            </span>
        </td>),
        "status": (item: InvoiceList.Item) => (<td>{CompanyService.getIoStatus().find(x => x.IOStatusID === item.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName()}</td>),
        "group": function GroupFieldImpl(item: InvoiceList.Item) {
            const [name, setName] = useState("");
            useEffect(() => {
                const getGroupName = async () => {
                    if (item.GroupID) {
                        const res = await GroupService.getGroupName(item.GroupID, item.personId);
                        setName(res);
                    }
                };
                getGroupName();
            }, [item.GroupID, item.personId]);
            return (<td>{name ?? ""}</td>);
        },
    };

    return (<tr key={data.IOID}>
        {tableHeaders.map(header => clientFieldIds.includes(header.fieldId) ? <ClientColumn key={header.fieldId} data={data} header={header} /> : <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialFields} />)}
    </tr>);
};

const ClientColumn = ({ data, header }: { data: InvoiceList.Item, header: TableHeaderWithFieldId }) => {
    const { currentLanguage } = TranslationService;
    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data.Person[header.fieldId as keyof InvoiceList.Item["Person"]];
    };
    const value = getValue();
    switch (header.fieldId) {
        case "Name":
            return (<td className="is-link">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.personId}`}>
                        {data.Person.Name}
                    </Link>
                </span>
            </td>);
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end"}>{formatCurrency(parseFloat(value), data.CurrencyID)}</td>;
        case DataType.Number:
            return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{formatDateShort(new Date(value))}</td>;
        case DataType.List:
            {
                const additionalId = data.Person.additionals.find(x => x.Id.toString() === header.fieldId)?.Id;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId?.toString());
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value);
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
};

function getTableHeaders() {
    const tableHeaders = InvoiceService.getTableHeaders();
    const idxStatus = tableHeaders.findIndex(x => x.fieldId === "status");
    if (idxStatus >= 0) {
        const status = { ...tableHeaders[idxStatus] };
        status.numeric = false;
        tableHeaders.splice(idxStatus, 1, status);
    }
    const clientHeaders = ClientService.getClientHeaders();

    const idMap: Record<string, number> = {
        "Name": ClientFields.Name,
        "Address": ClientFields.Address,
        "Phone": ClientFields.Phone,
    };

    const clientSortedIds = CompanyService.getPersonSortedFields().split(",");
    const sortedClientHeaders = clientSortedIds
        .map(x => clientHeaders.find(y => (idMap[y.fieldId]?.toString() ?? y.fieldId) === x))
        .filterFalsey();

    if (sortedClientHeaders.find(x => x.fieldId === "Name") === undefined) {
        sortedClientHeaders.unshift(new TableHeaderWithFieldId("client", TranslationService.translate.Client, false, true, "Name", DataType.Client));
    }

    tableHeaders.unshift(...sortedClientHeaders);
    tableHeaders.push(new TableHeaderWithFieldId(
        "last-activity",
        TranslationService.translate.LastActivity,
        false, false,
        "last-activity",
        DataType.ActivityType,
    ));
    return tableHeaders;
}

const Search = () => {
    const { applySearch } = useContext(TableContext);
    return (
        <div className="searcherFilterHeader" style={{ height: 40 }}>
            <SearchInput onSearch={applySearch} autosearch={false} />
        </div>
    );
};

export default ReportInvoice;