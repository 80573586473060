import { useCallback, useContext, useEffect, useState } from "react";
import { ReportInvoiceGroupResponse } from "../../entities/reports/reportInvoiceGroup/ReportInvoiceGroupResponse";
import CompanyService, { DataType } from "../../services/CompanyService";
import FilterService, { FilterCollection } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { formatCurrency, formatNumber } from "../../utils/FormatUtils";
import { sumList } from "../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import { TranslationService } from "../../services/TranslationService";
import Table, { TableHeader } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import { parseIntOrDefault } from "../../utils/ParseUtils";
import { useHistory } from "react-router-dom";
import { encodeFiltersToLink } from "./ReportCollection";
import AdvancedFilterService from "../../services/AdvancedFilterService";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

export class ReportInvoiceGroupTableContextValues extends TableContextValues<ReportInvoiceGroupResponse, { extraFilters: string[] }> {
    total = 0;
}

const ReportInvoiceGroup = () => {
    const { currentLanguage } = TranslationService;
    const history = useHistory();
    const pendingStatus = "selList=0&operatorList=0&selFields=-1010";

    const [response, setResponse] = useState<ReportInvoiceGroupResponse>({ list: [] as ReportInvoiceGroupResponse.Item[] } as ReportInvoiceGroupResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [groupBy, setGroupBy] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [filtersMap, setFiltersMap] = useState<string[]>([]);

    const requestData = async () => {
        setLoading(true);
        if (groupBy === 0) {
            setLoading(false);
            setError(false);
            return;
        }
        const result = await InvoiceService.getInvoiceGroupList(groupBy, filter);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setPageCount(totalPages);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [filter, groupBy]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters]);
        setFilter(AdvancedFilterService.filterStringsToQueryString(filters));
    };

    const tableValues = new ReportInvoiceGroupTableContextValues();
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.total = response?.total ?? 0;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.currentPage = currentPage;
    tableValues.pageCount = pageCount;

    const headers: TableHeader[] = [
        new TableHeader("value", TranslationService.translate.Value, false, false),
        new TableHeader("invoices", TranslationService.translate.Invoices, true, false),
        new TableHeader("pendingAmount", TranslationService.translate.PendingAmount, true, false),
        new TableHeader("percentage", "", true, false),
        new TableHeader("duegraph", "", true, false, ""),
        new TableHeader("", "", false, false, "w-300px"),
    ];

    const onFilterGroupChange = (value?: string) => {
        setGroupBy(parseIntOrDefault(value?.replace("additional-", "")));
    };

    const sendToInvoicesWithFilter = (dataValues: ReportInvoiceGroupResponse.Item) => {
        const filterDefinitions = FilterService.GetFiltersForPage(FilterCollection.ReportInvoiceGroup)[0].definitions;
        const definition = filterDefinitions.find(x => x?.Field === "additional-" + groupBy)!;
        let valueDef = dataValues.value!;
        if (definition) {
            if (definition?.Type === DataType.List) {
                valueDef = dataValues.valueid!.toString();
            }
            const filterFormated = AdvancedFilterService.filterToString({
                kind: definition.Type,
                definition: definition,
                value: valueDef,
                operator: 0
            });
            const filterAux = filterFormated && encodeURIComponent(encodeFiltersToLink([pendingStatus, filterFormated, ...filtersMap], -1) || "");
            history.push(`/${currentLanguage}/report/iolist${filterAux ? "?filter=" + filterAux : ""}`);
        }
        else if (filterDefinitions && groupBy === -1015) {
            valueDef = dataValues.valueid!.toString();
            const filterFormated = AdvancedFilterService.filterToString({
                kind: 1,
                definition: filterDefinitions.find(el => el.Field === '-1015')!,
                value: valueDef,
                operator: 0
            });
            const filterAux = filterFormated && encodeURIComponent(encodeFiltersToLink([pendingStatus, filterFormated, ...filtersMap], -1) || "");
            history.push(`/${currentLanguage}/report/iolist${filterAux ? "?filter=" + filterAux : ""}`);
        }
    };

    const groupByItems = FilterService.GetFiltersForPage(FilterCollection.ReportInvoiceGroup)[0].definitions
        .filter(x => (x.Field.startsWith("additional-") || x.Field === "-1015") && x.Type !== 2)
        .map(x => ({ text: x.Name, value: x.Field }));

    // groupByItems.find(x => x.value === "-1015")!.value = "-1"; // Falla

    const exportData = () => InvoiceService.exportInvoiceGroupList(groupBy, filter, 100);

    return (
        <TableContext.Provider value={tableValues}>
            <div className="container-fluid padding ">
                <div className="d-flex justify-content-between">
                    <h2>{TranslationService.translate.InvoiceSummary}</h2>
                </div>
                <div className="mh-100 p-0">
                    <div className="genericHeader">
                        <div className="searcherFilterHeader">
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.Export}
                                    icon="fa-light fa-arrow-down-to-line"
                                    onClick={exportData}
                                />
                            }
                            <AdvancedFiltersButton />
                            <div className="d-flex align-items-center">
                                <label className="col-form-label me-3">{TranslationService.translate.GroupInvoicesBy}:</label>
                                <Dropdown quickFilter optionLabel={TranslationService.translate.Select} onChange={onFilterGroupChange} items={groupByItems} />
                            </div>
                        </div>
                    </div>
                    <div className="pt-0">
                        <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportInvoiceGroup} />
                    </div>
                    <Table headers={headers} stickyHeader={true}
                        item={data => <ReportInvoiceGroupItem dataValues={data.data} sendToInvoicesWithFilter={sendToInvoicesWithFilter} />}>
                        <ReportInvoiceGroupTotal response={response} />
                    </Table>
                </div>
            </div>
        </TableContext.Provider>
    );
};

const ReportInvoiceGroupItem = ({ dataValues, sendToInvoicesWithFilter }: { dataValues: ReportInvoiceGroupResponse.Item, sendToInvoicesWithFilter: (dataValues: ReportInvoiceGroupResponse.Item) => void }) => {
    const { total } = useContext(TableContext) as ReportInvoiceGroupTableContextValues;
    const noDueShow = dataValues.amount - dataValues.due;
    const dueCalc = ((dataValues.due * 100) / dataValues.max);
    const noDueCalc = ((noDueShow * 100) / dataValues.max);

    return (
        <tr style={{ cursor: "pointer" }} onClick={() => sendToInvoicesWithFilter(dataValues)}>
            <td>{dataValues.value}</td>
            <td className="text-end">{dataValues.cant}</td>
            <td className="text-end">{formatCurrency(dataValues.amount, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end">{formatNumber(dataValues.amount / total * 100)}%</td>

            <td className='popover__wrapper'>
                <div className="progress progress-amount" style={{ marginBottom: "12px" }}>
                    <div className="progress-bar bg-danger bg-gradient bg-opacity-75" style={{ width: dueCalc + "%" }} />
                    <div className="progress-bar bg-primary bg-gradient progress-bar-stripped bg-opacity-75" style={{ width: noDueCalc + "%" }} />
                </div>
                <div className="popover__content">
                    <p className="popover__message" style={{ color: "red" }}>{TranslationService.translate.Due}: {formatCurrency(dataValues.due, CompanyService.getDefaultCurrencyId())}</p>
                    <p className="popover__message" style={{ color: "blue" }}>{TranslationService.translate.NoDue}: {formatCurrency(noDueShow, CompanyService.getDefaultCurrencyId())}</p>
                </div>
            </td>
            <td></td>
        </tr>
    );
};

const ReportInvoiceGroupTotal = ({ response }: { response: ReportInvoiceGroupResponse }) => {
    const { translate } = TranslationService;
    if ((response?.list.length ?? 0) === 0) {
        return <tr><td></td><td></td><td></td><td></td></tr>;
    }
    return (
        <tr className="font-weight-bold">
            <td>{translate.Total}</td>
            <td className="text-end">{sumList(response.list, x => x.cant)}</td>
            <td className="text-end">{formatCurrency(response.total, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end">{formatNumber(100)}%</td>
            <td></td>
        </tr>
    );
};


export default ReportInvoiceGroup;