import { TranslationService } from "../../services/TranslationService";
import { useEndpointData } from "../../utils/TableUtils";
import Loading from "../shared/components/Loading";
import ErrorMessage from "../shared/components/Error";
import { useMemo } from "react";
import { Switch } from "../../utils/Utils";
import { restTimezone } from "../../utils/ParseUtils";
import FloatingPanelService from "../shared/FloatingPanel";
import { ReportScheduledEditItem } from "./ReportScheduledEditItem";
import { ScheduledListEntities } from "./entities/ScheduledListResponse";
import { ScheduledService } from "../../services/ScheduledService";

export const ReportScheduledList = () => {
    const { loading, error, response, reload } = useEndpointData(ScheduledService.getScheduledReportList);
    const reportNames = useMemo(() => [
        TranslationService.translate.Activity,
        "",
        "",
        TranslationService.translate.ReportInvociesActivities,
        TranslationService.translate.AgingAnalysis,
    ], []);
    const daysOfWeek = useMemo(() =>
        [TranslationService.translate.Sunday, TranslationService.translate.Monday, TranslationService.translate.Tuesday, TranslationService.translate.Wednesday, TranslationService.translate.Thursday, TranslationService.translate.Friday, TranslationService.translate.Saturday,]
            .map((x, i) => ({ name: x, value: 1 << i })), []);

    if (loading || response === undefined) {
        return <Loading />;
    }
    if (error) {
        return <ErrorMessage onRefresh={reload} message={TranslationService.translate.ErrorLoadingList} />;
    }

    const FormatHour = (x: ScheduledListEntities.ListItem) => restTimezone(new Date(x.Hour)).getHours().toString().padStart(2, "0");
    const FormatWhen = (x: ScheduledListEntities.ListItem) =>
        Switch(
            [x.period === 3, () => `${TranslationService.translate.Monthly} ${TranslationService.translate.TheDayDate.replace("{DATE}", x.Frequency.toString().padStart(2, "0").replace("th", x.Frequency === 1 ? "st" : x.Frequency === 2 ? "nd" : "th"))} ${FormatHour(x)}hs`],
            [x.period === 2, () => `${TranslationService.translate.Weekly} (${daysOfWeek.filter(day => (day.value & x.Frequency) === day.value).map(x => x.name).join(", ")}) ${TranslationService.translate.At} ${FormatHour(x)}hs`],
            [x.period === 1, () => `${TranslationService.translate.Daily} ${TranslationService.translate.At} ${FormatHour(x)}hs`],
            [x.period === 0, TranslationService.translate.Manually],
        ) ?? "";

    const openScheduledReport = (x: ScheduledListEntities.ListItem) => FloatingPanelService.showPanel({
        children: <ReportScheduledEditItem data={x} reload={reload} />,
        title: TranslationService.translate.ScheduledReports,
    });

    return (
        <div className="container-fluid padding">
            <div className="d-flex justify-content-between">
                <h2>{TranslationService.translate.ScheduledReports}</h2>
            </div>
            <div className="mt-3">
                <div className="table-container-outstanding">
                    <table className={"table genericTable table-ellipsis mb-0"}>
                        <thead>
                            <tr>
                                <th>{TranslationService.translate.Reports}</th>
                                <th>{TranslationService.translate.Name}</th>
                                <th>{TranslationService.translate.HeaderWhen}</th>
                                <th>{TranslationService.translate.Destination}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {response?.list.map(x => <tr key={x.ScheduledReportID} className="pointer" onClick={() => openScheduledReport(x)}>
                                <td>{reportNames[x.Report] ?? ""}</td>
                                <td>{x.Name}</td>
                                <td>{FormatWhen(x)}</td>
                                <td>{Boolean(x.destination) ? `FTP: ${x.DestinationFTPFolder}` : `Email: ${x.DestinationEmail}`}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}