import CompanyService from "../../../services/CompanyService";
import { formatDateTimeDigits } from "../../../utils/FormatUtils";
import { ProcessLogListResponse } from "../../shared/entities/ProcessLogListResponse";
import { TranslationService } from "../../../services/TranslationService";
import { restTimezone } from "../../../utils/ParseUtils";
import FileService from "../../../services/FileService";
import { ToastService } from "../../shared/bootstrap/Toast";

const ProcessLogItem = ({ data }: { data: ProcessLogListResponse.Item }) => {
    const { translate } = TranslationService;
    const statusTooltip = data.Completed ? `${formatDateTimeDigits(new Date(data.Started!))} - ${formatDateTimeDigits(new Date(data.Completed!))}` :
        data.Started ? `${translate.Start}: ${formatDateTimeDigits(new Date(data.Started!))}` :
            `${formatDateTimeDigits(new Date(data.Created!))}`;

    const downloadProcessFile = () => {
        FileService.downloadReport(data.ProcessRequestID.toString())
            .then((response) => {
                if (response instanceof Error) {
                    ToastService.showToast('Error al descargar archivo', undefined, "danger");
                    return response;
                }
                ToastService.showToast('Descarga completa', undefined, "success");
                return response;
            })
    }
    return (
        <tr>
            <td width={100}>#{data.ProcessRequestID}</td>
            <td>{data.TypeText}</td>
            <td>{formatDateTimeDigits(restTimezone(new Date(data.Created)))}</td>
            <td>{data.UserName ?? CompanyService.getUsers().find(x => x.Id === data.UserID.toString())?.Value}</td>
            <td title={statusTooltip}>{data.Error ? translate.Failed : data.Completed ? translate.Completed : data.Started ? translate.Running : translate.Enqueued}</td>
            {/* // <a href={`https://www2.intiza.com/es/files/downloadreport?id=${data.ProcessRequestID}`}><i className="fa fa-download" /></a>} */}
            <td width={10}>{data.Downloadable &&
                <span onClick={downloadProcessFile}>
                    <i className="fa fa-download" />
                </span>}
            </td>
        </tr>);
};
export default ProcessLogItem;