import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { ReportAgingListResponse } from "../../entities/reports/ReportAging/ReportAgingResponse";
import CompanyService, { Entities } from "../../services/CompanyService";
import FilterService, { ENTITY_KEY, FilterCollection } from "../../services/FilterService";
import { TranslationService } from "../../services/TranslationService";
import { formatCurrency, formatIntizaDate } from "../../utils/FormatUtils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import Table, { TableHeader } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../shared/components/Loading";
import { CompanyAuth } from "../../entities/login/CompanyAuth";
import { ReportPaymentGroupListResponse } from "../../entities/reports/ReportPaymentGroup/ReportPaymentGroupResponse";
import TransactionService from "../../services/TransactionService";
import { ReportPaymentGroupRequest } from "../../entities/reports/ReportPaymentGroup/ReportPaymentGroupRequest";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";
import TooltipComponent from "../shared/TooltipComponent";
import moment from "moment";
import AdvancedFilterService, { Filter } from "../../services/AdvancedFilterService";

export class ReportPaymentGroupTableContextValues extends TableContextValues<ReportPaymentGroupListResponse, { extraFilters: string[] }> {
    constructor(public totalAmount: number) {
        super();
    }
}

export const ReportPaymentGroup = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const defaultFilter = searchParams.get("filter") ?? "";
    const defaultIds = searchParams.get("field") ?? "";
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportPaymentGroupListResponse>({} as ReportPaymentGroupListResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [groupBy, setGroupBy] = useState<{ value: number, id: string }[]>(() => {
        const ids = defaultIds.split(",").map(x => parseInt(x));
        return ids.length > 0 ? ids.map(x => ({ id: v4(), value: x })) : [{ id: v4(), value: 0 }];
    });
    const [filter, setFilter] = useState<string>(defaultFilter);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);

    const ids = useMemo(() => groupBy.map(x => x.value).filter(x => x), [groupBy]);

    const requestData = async () => {
        if (ids.length === 0) {
            setLoading(false);
            setError(false);
            return;
        }
        setLoading(true);
        const reportPaymentGroupRequest: ReportPaymentGroupRequest = {
            filter,
            id: ids[0],
            page: currentPage,
        };

        const result = await TransactionService.getPaymentgroup(reportPaymentGroupRequest);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setPageCount(totalPages);

        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [currentPage, filter, ids]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const tableValues = new ReportPaymentGroupTableContextValues(response.totalAmount ?? 0);
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.currentPage = currentPage;
    tableValues.pageCount = pageCount;
    tableValues.applyFilters = x => setFilter(FilterService.GetExtraFiltersRequestString(x.extraFilters) ?? "");

    const mapAdditional = (x: CompanyAuth.AdditionalDefinition) => ({
        text: x.Name,
        value: x.AdditionalDefinitionID,
        // key: x.Entity + "%" + x.Field,
    });

    const items = useMemo(() => [
        // { text: TranslationService.translate.FilterData.replace(ENTITY_KEY, TranslationService.translate.Payments), isTitle: true },
        // ...FilterService.GetFiltersForEntity(FilterEntity.Payment).definitions
        //     .filter(x => x.Entity === Entities.Payment)
        //     .map(x => ({ text: x.Name, value: parseInt(x.Field) })),
        { text: TranslationService.translate.FilterData.replace(ENTITY_KEY, TranslationService.translate.Client), isTitle: true },
        { text: TranslationService.translate.Name, value: -21 },
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Client)
            .map(mapAdditional),
        { text: TranslationService.translate.FilterData.replace(ENTITY_KEY, TranslationService.translate.Invoice), isTitle: true },
        { text: TranslationService.translate.InvoiceStatus, value: -9 },
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Invoice)
            .map(mapAdditional)
    ], []);

    const prevHeaders = useRef<TableHeader[]>([]);

    const headerMonth = () => {
        const monthValue = new Date().getMonth() + 1;
        const fromMonth = (1 + monthValue > 12) ? (1 + monthValue - 12) : (1 + monthValue);
        const toMonth = 12 + monthValue;
        const monthsYear = [];
        for (let i = fromMonth; i <= toMonth; i++) {
            const aux = i > 12 ? i - 12 : i;
            const year = i > 12 ? 0 : -1;
            let month = "";

            const yearComplete = new Date().getFullYear() + year;

            switch (aux) {
                case 1: month = TranslationService.translate.January; break;
                case 2: month = TranslationService.translate.February; break;
                case 3: month = TranslationService.translate.March; break;
                case 4: month = TranslationService.translate.April; break;
                case 5: month = TranslationService.translate.May; break;
                case 6: month = TranslationService.translate.June; break;
                case 7: month = TranslationService.translate.July; break;
                case 8: month = TranslationService.translate.August; break;
                case 9: month = TranslationService.translate.September; break;
                case 10: month = TranslationService.translate.October; break;
                case 11: month = TranslationService.translate.November; break;
                case 12: month = TranslationService.translate.December; break;
            }
            monthsYear.push({ id: aux, filterVal: aux, name: `${month} ${yearComplete}`, year: yearComplete });
        }
        return monthsYear;
    };

    const headers: TableHeader[] = useMemo(() => {
        if (loading) {
            return prevHeaders.current;
        }
        const defaultHeaders = headerMonth().map((x) => new TableHeader(x.filterVal.toString() + "-" + x.year, x.name, true, false, "txt-blue"));
        if (response?.list?.at(0)?.GroupedFields !== undefined && !loading) {
            defaultHeaders.unshift(...groupBy.map(x => items.find(y => "value" in y && y.value === x.value)?.text).filter(x => x).map(x => new TableHeader(x ?? "", x ?? "", false, false, "txt-full")));
        }
        prevHeaders.current = defaultHeaders;
        return defaultHeaders;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);


    const delGrouping = (index: number) => {
        groupBy.splice(index, 1);
        setGroupBy([...groupBy]);
    };

    const onFilterChange = (value: number, index: number) => {
        groupBy[index].value = (value);
        setGroupBy([...groupBy]);
    };

    const handleExport = async () => {
        TransactionService.exportPaymentGroup(response.itemCount, ids[0], filter ?? "");
    };

    return (
        <TableContext.Provider value={tableValues}>
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between">
                    <h2>{TranslationService.translate.PaymentSummary}</h2>
                </div>
                <div className="mh-100 p-0">
                    <div className="genericHeader mb-0">
                        <div className="searcherFilterHeader">
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.Export}
                                    icon="fa-light fa-arrow-down-to-line"
                                    onClick={handleExport}
                                />
                            }
                            <AdvancedFiltersButton />
                            <div className="d-flex align-items-center">
                                <p className="text-granite-gray me-3">{translate.GroupPaymentsBy}:</p>
                                {groupBy.map((x, index) => (
                                    <div key={x.id} className="d-flex ">
                                        <Dropdown quickFilter items={items} onChange={(value) => onFilterChange((value) ?? 0, index)} optionLabel={TranslationService.translate.Select} defaultValue={x.value} />
                                        {groupBy.length > 1 &&
                                            <button className="btn btn-link" onClick={() => delGrouping(index)}><i className="fa fa-trash text-danger"></i></button>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="isEmpty">
                        <AdvancedFilters page={FilterCollection.ReportPaymentGroup} />
                    </div>
                    {tableValues.loading && <Loading />}
                    {(!tableValues.loading) &&
                        <Table stickyHeader={true} headers={headers} item={ReportPaymentGroupItem(headers, filter, ids)}>
                            <ReportInvoiceGroupTotalUSD response={response} headers={headers} />
                        </Table>}
                </div>
            </div>
        </TableContext.Provider >
    );
};

const ReportPaymentGroupItem = (headers: TableHeader[], filter: string, ids: number[]) =>
    function ReportAgingGroupImpl({ data }: { data: ReportAgingListResponse.Item }) {
        const history = useHistory();
        const { currentLanguage } = TranslationService;
        const periodFilter = "selDate={periodDate}&selFields=-303";
        const ArrayHeaders = headers?.map(h => h.key).slice(1);

        const getQuerystring = (value: string, filterMonth?: boolean) => {
            const filterOptions = FilterService.GetFiltersForPage(FilterCollection.ReportPaymentGroup);
            const filterDefinitions = filterOptions.flatMap(x => x.definitions);
            const filterObjects: Filter[] = AdvancedFilterService.parseFilters(filter, filterOptions);
            const findDefinition = (id: number) => filterDefinitions.find(y => y.Field.replace("additional-", "") === (id === -21 ? -1 : id).toString())!;
            filterObjects.push(...ids.map((x, i) => {
                const definition = findDefinition(x);
                const valueLabel = data.GroupedFields[i]?.value;
                if (valueLabel === undefined) {
                    return undefined;
                }
                const valueId = definition.AdditionalDefinitionItems.find(x => x.Label === valueLabel)?.Value;
                const filter: Filter = {
                    definition: definition,
                    kind: definition.Type,
                    operator: (definition.Field !== "-1" && valueLabel === "") ? 7 : 0,
                    value: valueId ?? valueLabel,
                };
                return filter;
            }).filterFalsey());

            const stringFilters = filterObjects.map(AdvancedFilterService.filterToString);

            if (filterMonth) {
                const year = parseInt(value.split("-")[1]);
                const month = parseInt(value.split("-")[0]);
                const day = 1;
                const date = new Date(year, month - 1, day);
                const createdFilter = formatIntizaDate(date) + "-" + formatIntizaDate(moment(date).endOf("month").toDate());
                stringFilters.push(periodFilter.replace("{periodDate}", createdFilter));
            }

            history.push("/" + currentLanguage + "/report/payment?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...stringFilters]) || ""));
        };

        return (
            <tr key={data.value || "NotSpecified" + data.totalcant} >
                {data.GroupedFields?.map(x =>
                    <td key={x.showName} className="w-200 pointer" onClick={() => getQuerystring(x.value)}>
                        <TooltipComponent title={x.value}>
                            {x.value}
                        </TooltipComponent>
                    </td>)}
                {ArrayHeaders?.map((x, i) =>
                    <td className="text-end" key={i} onClick={() => getQuerystring(x, true)}>
                        {formatCurrency(data["Item" + (parseInt(x) - 1) as "Item1"] || 0)}
                    </td>)}
            </tr>
        );
    };

const ReportInvoiceGroupTotalUSD = ({ response, headers }: { response: ReportPaymentGroupListResponse, headers: TableHeader[] }) => {
    const ArrayHeaders = headers?.map(h => h.key).slice(1);

    return (
        <tr className="font-weight-bold">
            <td>{TranslationService.translate.Total}</td>

            {ArrayHeaders.length === 12 &&
                ArrayHeaders?.map((x, i) =>
                    <td className="text-end" key={i}>
                        {formatCurrency(response?.total[`${x}`])}
                    </td>)}
        </tr>
    );
};
