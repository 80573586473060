import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import TooltipComponent from "../TooltipComponent";
import MenuButton, { MenuButtonActions } from "./MenuButton";

const ExportButton = ({ reportTypeId, onClick, extraClassName }: { reportTypeId: number, onClick: (reportExportId: number | null) => void, extraClassName?: string }) => {
    const exportTypes = CompanyService.getReportExports().filter(x => x.Report === reportTypeId);
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => onClick(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === reportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => onClick(x.ReportExportID) })),
    ];
    if (exportTypes.length === 0) {        
        return (
            <TooltipComponent title={TranslationService.translate.Export} >
                <button type="button" className="btn button-outline-primary" onClick={() => onClick(null)}>
                    <i className={" fa-light fa-arrow-down-to-line"}></i>
                </button>
            </TooltipComponent>
        );
    }
    else {
        return (
            <TooltipComponent title={TranslationService.translate.Export} >
                <MenuButton text="" actions={exportItems} extraClassName={"select-intiza-outline " + extraClassName} icon="fa-light fa-arrow-down-to-line" hideChevron/>
            </TooltipComponent>
        );
    }
};

export default ExportButton;