import { useContext } from "react";
import { InvoiceListResponse } from "./entities/InvoiceListResponse";
import TableContext from "../../task/TableContext";
import CompanyService from "../../../services/CompanyService";
import { InvoiceGroupWithChildren } from "./InvoiceGroupWithChildren";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceGroupContext } from "./providers/InvoiceTableProvider";

type InvoiceGroupsType = {
    groups: number[]
}

export const InvoiceGroups = ({ groups }: InvoiceGroupsType) => {
    const response = useContext(TableContext).response as InvoiceListResponse | undefined;
    const headersObject: { [id: number]: string } = {};
    CompanyService.getAdditionalDefinitions().forEach(x => { headersObject[x.AdditionalDefinitionID] = x.Name; });
    const headers = groups.map(numero => headersObject[numero]).join(" / ");

    const { loading, responseData, maxDEbt } = useContext(InvoiceGroupContext);

    return loading ?
        <Loading /> :
        (
            <div className="table-container-outstanding">
                <table className="table">
                    <thead>
                        <tr>
                            <th>{headers}</th>
                            <th className='text-end'>{TranslationService.translate.Invoices}</th>
                            <th className='text-end'>{TranslationService.translate.Amount}</th>
                            <th>{TranslationService.translate.Due}/{TranslationService.translate.NoDue}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <InvoiceGroupWithChildren
                            spaceMargin={2}
                            maxDebt={maxDEbt}
                            dataValues={responseData}
                            currencyTotals={response?.currencyTotals}
                            firstOpen={true}
                        />
                    </tbody>
                </table>
            </div>
        );
};

