import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import ReportsBrackets from "./components/ReportsBrackets";
import ReportsCard from "./components/ReportsCard";
import ClientService from "../../services/ClientService";
import { ReportClientMapResponse } from "../client/entities/ClientDebtRecoveryAnalysisRequest";
import { FilterCollection } from "../../services/FilterService";
import { formatCurrency } from "../../utils/FormatUtils";
import Loading from "../shared/components/Loading";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import ErrorMessage from "../shared/components/Error";
import { useHistory } from "react-router-dom";
import { encodeFilters } from "../../utils/Utils";

const encodeFiltersToLink = (filters: string[], customIndex?: number) => {
    return filters.map((value, index) => "filter" + (customIndex || index + 1) + "=" + encodeURIComponent(value)).join("&");
};

const ReportClientMap = () => {
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState<string>();
    const [response, setResponse] = useState<ReportClientMapResponse>();
    const [loading, setLoading] = useState(true);
    const [filtersSelected, setFiltersSelected] = useState({ days: 30, filter: "" });
    const [filtersMap, setFiltersMap] = useState<string[]>([]);

    const history = useHistory();
    const { translate, currentLanguage } = TranslationService;

    const getData = async () => {
        setLoading(true);
        if (filtersSelected.days < 0 || isNaN(filtersSelected.days)) {
            setMsgError(translate.CompleteToContinue);
            setLoading(false);
            setError(true);
            return;
        }
        const result = await ClientService.getClientMapReport(filtersSelected);
        if (result instanceof Error) {
            setMsgError(translate.ErrorProcessingRequest);
            setError(true);
            setLoading(false);
            setResponse(undefined);
            return;
        }
        setMsgError(undefined);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const handleChangeDays = (value: ChangeEvent<HTMLInputElement>) =>
        setFiltersSelected(p => ({ ...p, days: value.target.valueAsNumber }));

    const applyFilters = (filters: string[]) => {
        setFiltersMap([...filters]);
        const filterEncoded = encodeFilters(filters);
        setFiltersSelected(p => ({ ...p, filter: filterEncoded }));
    };

    const navigateClientFilter = useCallback((customerKey: number) => {
        let customPath = `/${currentLanguage}/client?filter=filter0%3damount%253d`;

        switch (customerKey) {
            case 0:
                customPath = `/${currentLanguage}/client${filtersMap.length > 0 ? "?filter=filter0%3damount%253d%26" + encodeURIComponent(encodeFiltersToLink(filtersMap) || "") : ""}`;
                break;
            case 1:
                customPath = `${customPath}0%2526selFields%253d-9%2526selOperator%253d0${filtersMap.length > 0 ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap) || "") : ""}`;
                break;
            case 2:
                customPath = `${customPath}0%2526selFields%253d-9%2526selOperator%253d5%26filter1%3damount%253d0%2526selFields%253d-10%2526selOperator%253d0${filtersMap.length > 0 ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 2) || "") : ""}`;
                break;
            case 3:
                customPath = `${customPath}0%2526selFields%253d-10%2526selOperator%253d5${filtersMap.length > 0 ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap) || "") : ""}`;
                break;
            case 4:
                customPath = `${customPath}0%2526selFields%253d-10%2526selOperator%253d5%26filter1%3damount%253d${filtersSelected.days}%2526selFields%253d-18%2526selOperator%253d1${filtersMap.length > 0 ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 2) || "") : ""}`;
                break;
            case 5:
                customPath = `${customPath}0%2526selFields%253d-10%2526selOperator%253d5%26filter1%3damount%253d${filtersSelected.days}%2526selFields%253d-10%2526selOperator%253d5%26filter2%3damount%253d30%2526selFields%253d-18%2526selOperator%253d2${filtersMap.length > 0 ? "%26" + encodeURIComponent(encodeFiltersToLink(filtersMap, 3) || "") : ""}`;
                break;

        }
        history.push(customPath);
    }, [currentLanguage, history, filtersSelected.days, filtersMap]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { getData(); }, [filtersSelected.filter]);

    return (
        <>
            <div className="container-fluid padding reports mb-5">
                <h2>{translate.ClientsMap}</h2>
                <div className="p-0 mb-4">
                    <div className="genericHeader mb-0">
                        <div className="searcherFilterHeader align-items-center">
                            <AdvancedFiltersButton />
                            <label className="form-label col-md-1 mb-0" style={{ minWidth: 150 }}>{translate.DiasGestionManual}:</label>
                            <input className="form-control" style={{ maxWidth: 100 }} type="number" defaultValue={30} autoFocus onChange={handleChangeDays}></input>

                            <button type="button" className="btn btn-primary py-1 " onClick={getData}>{translate.Apply}</button>
                        </div>
                    </div>
                    <div className="isEmpty">
                        <AdvancedFilters page={FilterCollection.OnlyClient} onFilterApply={applyFilters} />
                    </div>
                </div>

                {loading &&
                    <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                        <Loading />
                    </div>}
                {!loading && error &&
                    <div className="container-fluid padding reports d-flex justify-content-center align-items-center vh-100">
                        <ErrorMessage message={msgError} onRefresh={getData} />
                    </div>}
                {!loading && !error && response &&
                    <div className="d-flex tree ">
                        <div className="d-flex align-items-center">
                            <ReportsCard
                                type={"ClientMap"}
                                title={translate.Total}
                                action={() => navigateClientFilter(0)}
                                customers={response.totalcount}
                                total={formatCurrency(response.totaldebt)} />
                        </div>
                        <div className="d-flex gap-4">
                            <ReportsBrackets />
                            <div className="d-flex flex-column justify-content-center gap-3">
                                <ReportsCard
                                    type={"ClientMap"}
                                    title={translate.WithoutDebts}
                                    action={() => navigateClientFilter(1)}
                                    customers={response.nodebtcount}
                                    total={formatCurrency(response.nodebt)} />
                                <ReportsCard
                                    type={"ClientMap"}
                                    title={translate.WithoutOverdueInvoices}
                                    action={() => navigateClientFilter(2)}
                                    customers={response.debtcount}
                                    total={formatCurrency(response.debt)} />
                                <div className="d-flex align-items-center withChildren">
                                    <ReportsCard
                                        type={"ClientMap"}
                                        title={translate.WithOverdueInvoices}
                                        action={() => navigateClientFilter(3)}
                                        customers={response.duedebtcount}
                                        total={formatCurrency(response.duedebt)} />
                                    <div className="d-flex">
                                        <div className="d-flex gap-4">
                                            <ReportsBrackets />
                                            <div className="d-flex flex-column justify-content-center gap-3">
                                                <ReportsCard
                                                    type={"ClientMap"}
                                                    background="blue"
                                                    title={translate.WithManualActivitiesInTheLastNDays.replace("{0}", filtersSelected.days.toString())}
                                                    action={() => navigateClientFilter(4)}
                                                    customers={response.activitycount}
                                                    total={formatCurrency(response.activitydebt)} />
                                                <ReportsCard
                                                    type={"ClientMap"}
                                                    background="red"
                                                    title={translate.WithoutManualActivitiesInTheLastNDays.replace("{0}", filtersSelected.days.toString())}
                                                    action={() => navigateClientFilter(5)}
                                                    customers={response.noactivitycount}
                                                    total={formatCurrency(response.noactivitydebt)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </>
    );

};

export default ReportClientMap;