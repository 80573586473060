import { FilterCollection } from "../../../services/FilterService";
import AdvancedFilters, { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";

export const RrsAdvancedFilters = ({ onFilterApply }: { onFilterApply: (filter: string[]) => void }) => {

    return (
        <>
            <div className="genericHeader row justify-content-start mb-0">
                <div className="col-auto">
                    <div className="d-flex align-items-center">
                        <AdvancedFiltersButton />
                    </div>
                </div>
            </div>
            <div className="isEmpty">
                <AdvancedFilters page={FilterCollection.Client} onFilterApply={onFilterApply} />
            </div>
        </>);
};