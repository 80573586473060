import { useCallback, useContext, useState } from "react";
import { ReportInvoiceGroupResponse } from "../../entities/reports/reportInvoiceGroup/ReportInvoiceGroupResponse";
import CompanyService from "../../services/CompanyService";
import FilterService, { FilterCollection, FilterEntity } from "../../services/FilterService";
import { formatCurrency, formatNumber } from "../../utils/FormatUtils";
import { Cast, sumList } from "../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import { TranslationService } from "../../services/TranslationService";
import { TableHeader } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import { parseIntOrDefault } from "../../utils/ParseUtils";
import { calculatePageCount, setCurrentPageHelper, useEndpointData } from "../../utils/TableUtils";
import ClientService, { ClientGroupReportRequest } from "../../services/ClientService";
import Loading from "../shared/components/Loading";
import ErrorMessage from "../shared/components/Error";
import AdvancedFilterService, { FilterLine } from "../../services/AdvancedFilterService";
import { useHistory } from "react-router-dom";

export class ReportInvoiceGroupTableContextValues extends TableContextValues<ReportInvoiceGroupResponse, { extraFilters: string[] }> {
    total = 0;
}
const ADDITIONAL_PREFIX = "additional-";

export const ReportClientGroup = () => {
    const history = useHistory();
    const [request, setRequest] = useState<ClientGroupReportRequest>({
        filter: "",
        id: 0,
        page: 0,
        pageSize: 50,
    });
    const context = useEndpointData(useCallback(() => {
        if (request.id === 0) {
            return Promise.resolve(Cast<ReturnType<typeof ClientService.getGroupReport>>(undefined));
        }
        return ClientService.getGroupReport(request);
    }, [request]));
    context.pageCount = calculatePageCount(context.response);
    context.setCurrentPage = setCurrentPageHelper(setRequest, context.pageCount);
    context.currentPage = request.page;

    context.applyFilters = (filters: { extraFilters: string[] }) => setRequest(x => ({ ...x, filter: FilterService.GetExtraFiltersRequestString(filters.extraFilters) ?? "" }));

    const headers: TableHeader[] = [
        new TableHeader("value", TranslationService.translate.Value, false, false),
        new TableHeader("invoices", TranslationService.translate.Clients, true, false),
        new TableHeader("pendingAmount", TranslationService.translate.Amount, true, false),
        new TableHeader("percentage", "", true, false),
        new TableHeader("duegraph", "", true, false, ""),
    ];

    const onFilterGroupChange = (value?: string) => {
        setRequest(x => ({ ...x, id: (parseIntOrDefault(value?.replace(ADDITIONAL_PREFIX, ""))) }));
    };

    const sendToInvoicesWithFilter = (dataValues: ReportInvoiceGroupResponse.Item) => {
        const filterOption = FilterService.GetFiltersForEntity(FilterEntity.Client);
        const definition = filterOption.definitions.find(x => x.Field === ADDITIONAL_PREFIX + request.id)!;
        const filters = AdvancedFilterService.parseFilters(request.filter, [filterOption]);
        const selectedFilter = new FilterLine();
        selectedFilter.definition = definition;
        selectedFilter.kind = definition.Type;
        selectedFilter.value = dataValues.valueid?.toString() ?? dataValues.value!;
        selectedFilter.operator = 0;
        filters.push(selectedFilter);
        const filterAux = AdvancedFilterService.filtersToRequestString(filters);
        history.push(`/${TranslationService.currentLanguage}/client?filter=${filterAux}`);
    };

    const groupByItems = FilterService.GetFiltersForPage(FilterCollection.OnlyClient)[0].definitions
        .filter(x => x.Field.startsWith(ADDITIONAL_PREFIX) || x.Field === "-1015")
        .map(x => ({ text: x.Name, value: x.Field }));

    return (
        <TableContext.Provider value={context}>
            <div className="container-fluid padding ">
                <div className="d-flex justify-content-between">
                    <h2>{TranslationService.translate.ClientSummary}</h2>
                </div>
                <div className="mh-100 p-0">
                    <div className="genericHeader mb-0">
                        <div className="searcherFilterHeader">
                            <AdvancedFiltersButton />
                            <div className="d-flex align-items-center">
                                <p className="text-granite-gray me-3">{TranslationService.translate.GroupInvoicesBy.replace(TranslationService.translate.Invoices.toLowerCase(), TranslationService.translate.Clients.toLowerCase())}:</p>
                                <Dropdown quickFilter optionLabel={TranslationService.translate.Select} onChange={onFilterGroupChange} items={groupByItems} />
                            </div>
                        </div>
                    </div>
                    <div className="isEmpty">
                        <AdvancedFilters page={FilterCollection.OnlyClient} />
                    </div>
                    {context.loading && <Loading />}
                    {context.error && !context.loading && <ErrorMessage onRefresh={context.reload} />}
                    {!context.error && !context.loading && request.id !== 0 &&
                        <div className="table-container-outstanding px-0">
                            {!context.error && !context.loading && request.id !== 0 &&
                                <table className={"table table-ellipsis px-2"}>
                                    <thead>
                                        <tr>
                                            {headers.map(x => <th key={x.key} className={"px-2 " + (x.numeric ? "text-end" : "")}>{x.label()}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {context.response?.list.map(x => <ReportInvoiceGroupItem key={x.Def} dataValues={x} sendToInvoicesWithFilter={sendToInvoicesWithFilter} />)}
                                        {context.response && <ReportInvoiceGroupTotal response={context.response} />}
                                    </tbody>
                                </table>}
                        </div>
                    }
                </div>
            </div>
        </TableContext.Provider>
    );
};

const ReportInvoiceGroupItem = ({ dataValues, sendToInvoicesWithFilter }: { dataValues: ReportInvoiceGroupResponse.Item, sendToInvoicesWithFilter: (dataValues: ReportInvoiceGroupResponse.Item) => void }) => {
    const total = useContext(TableContext).response.total || 0.000001;
    const noDueShow = dataValues.amount - dataValues.due;
    const dueCalc = ((dataValues.due * 100) / dataValues.max);
    const noDueCalc = ((noDueShow * 100) / dataValues.max);

    return (
        <tr style={{ cursor: "pointer" }} onClick={() => sendToInvoicesWithFilter(dataValues)}>
            <td className="px-2">{dataValues.value}</td>
            <td className="text-end px-2">{dataValues.cant}</td>
            <td className="text-end px-2">{formatCurrency(dataValues.amount, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end px-2">{formatNumber(dataValues.amount / total * 100)}%</td>
            <td className='popover__wrapper px-2'>
                <div className="progress progress-amount" style={{ marginBottom: "12px" }}>
                    <div className="progress-bar bg-danger bg-gradient bg-opacity-75" style={{ width: dueCalc + "%" }} />
                    <div className="progress-bar bg-primary bg-gradient progress-bar-stripped bg-opacity-75" style={{ width: noDueCalc + "%" }} />
                </div>
                <div className="popover__content">
                    <p className="popover__message" style={{ color: "red" }}>{TranslationService.translate.Due}: {formatCurrency(dataValues.due, CompanyService.getDefaultCurrencyId())}</p>
                    <p className="popover__message" style={{ color: "blue" }}>{TranslationService.translate.NoDue}: {formatCurrency(noDueShow, CompanyService.getDefaultCurrencyId())}</p>
                </div>
            </td>
        </tr>
    );
};

const ReportInvoiceGroupTotal = ({ response }: { response: ReportInvoiceGroupResponse }) => {
    const { translate } = TranslationService;
    if ((response?.list.length ?? 0) === 0) {
        return <tr><td></td><td></td><td></td><td></td></tr>;
    }
    return (
        <tr className="font-weight-bold">
            <td className="px-2">{translate.Total}</td>
            <td className="text-end px-2">{sumList(response.list, x => x.cant)}</td>
            <td className="text-end px-2">{formatCurrency(response.total, CompanyService.getDefaultCurrencyId())}</td>
            <td className="text-end px-2">{response.total !== 0 ? (formatNumber(100) + "%") : "-"}</td>
            <td></td>
        </tr>
    );
};