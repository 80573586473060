import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { ReportAgingRequest } from "../../entities/reports/ReportAging/ReportAgingRequest";
import { ReportAgingListResponse } from "../../entities/reports/ReportAging/ReportAgingResponse";
import CompanyService, { Entities } from "../../services/CompanyService";
import FilterService, { ENTITY_KEY, FilterCollection } from "../../services/FilterService";
import InvoiceService from "../../services/InvoiceService";
import { TranslationService } from "../../services/TranslationService";
import { formatInteger, formatIntizaDate } from "../../utils/FormatUtils";
import { OptionalMap, sumList } from "../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import Table, { TableHeader } from "../shared/Table";
import TooltipComponent from "../shared/TooltipComponent";
import TableContext, { TableContextValues } from "../task/TableContext";
import Collapsable from "../shared/bootstrap/Collapsable";
import { Link, useLocation } from "react-router-dom";
import Loading from "../shared/components/Loading";
import AdvancedFilterService, { Filter } from "../../services/AdvancedFilterService";
import moment from "moment";
import { CompanyAuth } from "../../entities/login/CompanyAuth";

export class ReportAgingGroupTableContextValues extends TableContextValues<ReportAgingListResponse, { extraFilters: string[] }> {
    constructor(public totalAmount: number) {
        super();
    }
}
const ReportAgingGroup = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const defaultFilter = searchParams.get("filter") ?? "";
    const defaultIds = searchParams.get("field") ?? "";
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportAgingListResponse>({} as ReportAgingListResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [groupBy, setGroupBy] = useState<{ value: number, id: string }[]>(() => {
        const ids = defaultIds.split(",").map(x => parseInt(x));
        return ids.length > 0 ? ids.map(x => ({ id: v4(), value: x })) : [{ id: v4(), value: 0 }];
    });
    const [filter, setFilter] = useState<string>(defaultFilter);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [showPercentages, setShowPercentages] = useState(false);

    const ids = useMemo(() => groupBy.map(x => x.value).filter(x => x), [groupBy]);

    const requestData = async () => {
        if (ids.length === 0) {
            setLoading(false);
            setError(false);
            return;
        }
        setLoading(true);
        const reportAgingRequest: ReportAgingRequest = {
            filter,
            ids,
            page: currentPage,
        };
        const result = await InvoiceService.getReportAging(reportAgingRequest);
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setPageCount(totalPages);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [currentPage, filter, ids]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const tableValues = new ReportAgingGroupTableContextValues(response.totalAmount ?? 0);
    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.setCurrentPage = _setCurrentPage;
    tableValues.currentPage = currentPage;
    tableValues.pageCount = pageCount;
    tableValues.applyFilters = x => setFilter(FilterService.GetExtraFiltersRequestString(x.extraFilters) ?? "");

    const mapAdditional = (x: CompanyAuth.AdditionalDefinition) => ({
        text: x.Name,
        value: x.AdditionalDefinitionID,
        // key: x.Entity + "%" + x.Field,0
    });
    const items = useMemo(() =>
        [
            { text: TranslationService.translate.FilterData.replace(ENTITY_KEY, TranslationService.translate.Client), isTitle: true },
            { text: TranslationService.translate.Name, value: -21 },
            ...CompanyService.getAdditionalDefinitions()
                .filter(x => x.Entity === Entities.Client)
                .map(mapAdditional),
            { text: TranslationService.translate.FilterData.replace(ENTITY_KEY, TranslationService.translate.Invoice), isTitle: true },
            ...CompanyService.getAdditionalDefinitions()
                .filter(x => x.Entity === Entities.Invoice)
                .map(mapAdditional),
        ], []);

    const prevHeaders = useRef<TableHeader[]>([]);
    const headers: TableHeader[] = useMemo(() => {
        if (loading) {
            return prevHeaders.current;
        }
        const defaultHeaders = [
            ...CompanyService.GetAgeingValues().map(x => new TableHeader(x.filterVal, x.name, true, false, (x.minDays === undefined || (0 > x.minDays)) ? "txt-red" : "txt-blue")),
            new TableHeader("dues", TranslationService.translate.Dues, true, false, "txt-red"),
            new TableHeader("notdue", TranslationService.translate.NotDue, true, false, "txt-blue"),
            new TableHeader("total", TranslationService.translate.Total, true, false),
        ];
        if (response?.list?.at(0)?.GroupedFields !== undefined && !loading) {
            defaultHeaders.unshift(...groupBy.map(x => items.find(y => "value" in y && y.value === x.value)?.text).filter(x => x).map(x => new TableHeader(x ?? "", x ?? "", true, false, "text-start")));
        }
        prevHeaders.current = defaultHeaders;
        return defaultHeaders;
        // Safety: We have multiple dependencies, but we really want to update the headers when it stops loading
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const addGrouping = () => {
        groupBy.push({ id: v4(), value: 0 });
        setGroupBy([...groupBy]);
    };

    const delGrouping = (index: number) => {
        groupBy.splice(index, 1);
        setGroupBy([...groupBy]);
    };

    const onFilterChange = (value: number, index: number) => {
        groupBy[index].value = value;
        setGroupBy([...groupBy]);
    };

    const handleExport = async () => {
        InvoiceService.exportAgingGroup(response.itemCount, ids, filter ?? "");
    };

    return (
        <TableContext.Provider value={tableValues}>
            <div className="container-fluid padding">
                <div className="mh-100 px-0">
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-3">{TranslationService.translate.AgingAnalysis}</h2>
                    </div>
                    <div className="d-flex group-invoices border-bottom-0 mb-0 py-0 pt-2">
                        <label className="col-form-label me-3">{translate.GroupDebtsBy}:</label>
                        {groupBy.map((x, index) => (
                            <div key={x.id} className="d-flex ">
                                <Dropdown items={items} onChange={(value) => onFilterChange(value ?? 0, index)} optionLabel={TranslationService.translate.Select} defaultValue={x.value} />
                                {groupBy.length > 1 &&
                                    <button className="btn btn-link" onClick={() => delGrouping(index)}><i className="fa fa-trash text-danger"></i></button>
                                }
                            </div>
                        ))}
                        {ids.length > 0 && <button className="btn btn-link pl-0" onClick={() => addGrouping()} style={{ height: 40 }}>+ {translate.AddGroupBy}</button>}
                    </div>
                    <Collapsable show={ids.length > 0}>
                        <>
                            <div className="genericHeader justify-content-start">
                                <div>
                                    {CompanyService.canDo("export") &&
                                        <TooltipComponent title={TranslationService.translate.Export} >
                                            <button className="btn button-outline-primary" onClick={handleExport}><i className="fa-light fa-arrow-down-to-line text-granite-gray"></i></button>
                                        </TooltipComponent>
                                    }
                                </div>
                                <AdvancedFiltersButton />
                                <div className="searcherFilterHeader align-items-center">
                                    <Dropdown quickFilter items={[{ text: translate.ShowAmounts, value: 0 }, { text: translate.ShowPercentages, value: 1 },]} onChange={() => setShowPercentages(x => !x)} />
                                    {!showPercentages && <label className="col-form-label">{TranslationService.translate.ValuesExpressedBy} {CompanyService.getCurrencies().find(x => x.CurrencyId === CompanyService.getDefaultCurrencyId())!.Symbol}</label>}
                                </div>
                            </div>
                            <AdvancedFilters page={FilterCollection.ReportInvoice} defaultValue={defaultFilter} />
                            {tableValues.loading && <Loading />}
                            {!tableValues.loading && <Table stickyHeader={true} headers={headers} item={ReportAgingGroupItem(showPercentages, response.totalAmount, filter, ids)}>
                                <ReportInvoiceGroupTotalUSD response={response} showPercentage={showPercentages} />
                            </Table>}

                        </>
                    </Collapsable>
                </div>
            </div>
        </TableContext.Provider>
    );
};

const ReportAgingGroupItem = (showPercentage: boolean, totalAmount: number, filters: string, ids: number[]) => function ReportAgingGroupImpl({ data }: { data: ReportAgingListResponse.Item }) {
    const intoPercentage = (value: number) => formatInteger(value / totalAmount * 100) + "%";
    const intoCurrency = formatInteger;
    const [tableFormat, tooltipFormat] = showPercentage ? [intoPercentage, intoCurrency] : [intoCurrency, intoPercentage];
    const getQuerystring = (index: number) => {
        const filterOptions = FilterService.GetFiltersForPage(FilterCollection.ReportInvoice);
        const filterDefinitions = filterOptions.flatMap(x => x.definitions);
        const filterObjects: Filter[] = AdvancedFilterService.parseFilters(filters, filterOptions);

        const findDefinition = (id: number) => filterDefinitions.find(y => y.Field.replace("additional-", "") === (id === -21 ? -1 : id).toString())!;
        filterObjects.push(...ids.map((x, i) => {
            const definition = findDefinition(x);
            const valueLabel = data.GroupedFields[i]?.value;
            if (valueLabel === undefined) {
                return undefined;
            }
            const valueId = definition.AdditionalDefinitionItems.find(x => x.Label === valueLabel)?.Value;
            const filter: Filter = {
                definition: definition,
                kind: definition.Type,
                operator: (definition.Field !== "-1" && valueLabel === "") ? 7 : 0,
                value: valueId ?? valueLabel,
            };
            return filter;
        }).filterFalsey());

        const dueFilterDefinition = findDefinition(-1024);
        const aging = CompanyService.GetAgeingValues()[index];
        const from = OptionalMap(aging.minDays, x => moment().add(x, "days").toDate()) ?? new Date(1970, 0, 1);
        const to = OptionalMap(aging.maxDays, x => moment().add(x, "days").toDate()) ?? new Date(2200, 0, 1);

        filterObjects.push({
            definition: dueFilterDefinition,
            kind: dueFilterDefinition?.Type,
            value: formatIntizaDate(from) + "-" + formatIntizaDate(to),
        });

        const amountFilterDefinition = findDefinition(-1012);
        filterObjects.push({
            definition: amountFilterDefinition,
            kind: amountFilterDefinition?.Type,
            operator: 5,
            value: "0",
        });

        const filerFormatedObjext = filterObjects.map(elObj => {
            const objAux = { ...elObj }
            if (objAux.kind === 2 && objAux?.value) {
                if ((objAux.value?.includes('-') || objAux.value?.includes('/')) && objAux.value?.length > 8) {
                    const isNotRange = objAux.value.split('-').length > 2 || objAux.value.split('/').length > 2;
                    if (isNotRange) {
                        const dateFormatBasic = objAux?.value.split(' ')[0].replaceAll('-', '/')
                        const dateAux = formatIntizaDate(new Date(dateFormatBasic as string))
                        objAux.value = dateAux;
                    }
                }
            }
            return objAux;
        })
        const stringFilters = filerFormatedObjext.map(AdvancedFilterService.filterToString);
        return "iolist?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...stringFilters]) || "");
    };

    return (
        <tr key={data.value || "NotSpecified" + data.totalcant}>
            {data.GroupedFields?.map(x => <td key={x.showName}>{x.value}</td>)}
            {Array.from({ length: 8 }).map((x, i) => <td className="text-end" key={i}>
                <Link to={getQuerystring(i)}>
                    {/* <div onClick={() => getQuerystring(i)}> */}
                    <TooltipComponent title={tooltipFormat(data["Item" + i as "Item1"])}>{tableFormat(data["Item" + i as "Item1"])}</TooltipComponent>
                    {/* </div> */}
                </Link>
            </td>)}
            <td className="text-end"><TooltipComponent title={tooltipFormat(data.due)}>{tableFormat(data.due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(data.amount - data.due)}>{tableFormat(data.amount - data.due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat((data.amount))}>{tableFormat(data.amount)}</TooltipComponent></td>
        </tr>
    );
};

const ReportInvoiceGroupTotalUSD = ({ response, showPercentage }: { response: ReportAgingListResponse, showPercentage: boolean }) => {
    const { totalAmount } = useContext(TableContext) as ReportAgingGroupTableContextValues;
    const intoPercentage = (value: number) => formatInteger(value / totalAmount * 100) + "%";
    const intoCurrency = formatInteger;
    const [tableFormat, tooltipFormat] = showPercentage ? [intoPercentage, intoCurrency] : [intoCurrency, intoPercentage];
    const due = sumList(response.list, x => x.due);
    const noDue = sumList(response.list, x => x.amount - x.due);
    const total = response.totalAmount;
    return (
        <tr className="font-weight-bold">
            <td>{TranslationService.translate.Total}</td>
            {response.list[0].GroupedFields.filter((x, i) => i !== 0).map(x => <td key={x.showName}></td>)}
            {response.totals.map((x, i) => <td key={i} className="text-end"><TooltipComponent title={tooltipFormat(x)}>{tableFormat(x)}</TooltipComponent></td>)}
            <td className="text-end"><TooltipComponent title={tooltipFormat(due)}>{tableFormat(due)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(noDue)}>{tableFormat(noDue)}</TooltipComponent></td>
            <td className="text-end"><TooltipComponent title={tooltipFormat(total)}>{tableFormat(total)}</TooltipComponent></td>
        </tr>
    );
};
export default ReportAgingGroup;